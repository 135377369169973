import React from "react";
import { Button, Row, Col } from "reactstrap";
import { modalsOperations } from "state/ducks/modal";
import { useDispatch } from "react-redux";

const Supplier = (props) => {
    const dispatch = useDispatch();

    const IconDocument = () => (
        <svg width="25" height="25" viewBox="0 0 30 30">
            <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50" width="30"
                height="30">
                <path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z" /></svg>
        </svg>
    );

    const { supplier, index, isAuthenticated, isSupplier, cartType, documents } = props;

    return (
        <Col key={index} sm={12}>
            <div className="article-bloc">
                <Row>
                    <Col md="2" className="text-left">
                        <b style={{ fontSize: "12px" }}>{supplier.lotName}</b>
                    </Col>
                    <Col md="1" className="text-left">
                        <p>{supplier.department}</p>
                    </Col>
                    {cartType === "formalized" && (
                        <Col md="1" className="text-left">
                            <p><b>{supplier.rank}</b></p>
                        </Col>
                    )}
                    <Col md="1" className="text-left">
                        <p>
                            <b>
                                {supplier?.supplier?.logoUrl && (
                                    <span>
                                        <img
                                            alt="logo"
                                            src={supplier.supplier.logoUrl}
                                            style={{ maxWidth: "50px" }}
                                        />
                                        <br />
                                    </span>
                                )}
                                <span>{supplier.supplier.name}</span>
                            </b>
                        </p>
                    </Col>
                    {isAuthenticated && !isSupplier ? (
                        <>
                            <Col
                                md={cartType === "formalized" ? "2" : "4"}
                                className="text-left"
                            >
                                {supplier.supplier && (
                                    <p style={{ fontSize: "12px" }}>
                                        <b>
                                            {supplier.supplier.firstname} {supplier.supplier.lastname}
                                            <br />
                                        </b>
                                        <b>
                                            {supplier.supplier.email}
                                            <br />
                                        </b>
                                        {supplier.supplier.phone && (
                                            <b>
                                                {supplier.supplier.phone}
                                                <br />
                                            </b>
                                        )}
                                    </p>
                                )}
                            </Col>
                            {cartType === "formalized" && (
                                <Col md="5" className="text-center"
                                >
                                    {supplier?.supplier?.ribUrl && (
                                        <div className="document-list">
                                            <a
                                                href={supplier.supplier.ribUrl}
                                                target="_blank"
                                                id="AttestationIcon"
                                                rel="noopener noreferrer"
                                                className="fileLinkDocument"
                                                title="RIB"
                                            >
                                                <span>RIB</span>
                                            </a>
                                            {supplier?.supplier?.companyInsuranceFileUrl && (
                                                <>
                                                    <span className="document-separator"> | </span>
                                                    <a
                                                        href={supplier.supplier.companyInsuranceFileUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="fileLinkDocument"
                                                        title="Assurance"
                                                    >
                                                        <span>Assurance</span>
                                                    </a>
                                                </>
                                            )}
                                            {supplier?.supplier?.fiscalRegularityFileUrl && (
                                                <>
                                                    <span className="document-separator"> | </span>
                                                    <a
                                                        href={supplier.supplier.fiscalRegularityFileUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="fileLinkDocument"
                                                        title="Attestation"
                                                    >
                                                        <span>Attestation</span>
                                                    </a>
                                                </>
                                            )}
                                            {supplier?.supplier?.finalPDFUrls &&
                                                supplier.supplier.finalPDFUrls.map((finalPDFUrl, i) => (
                                                    <React.Fragment key={i}>
                                                        <span className="document-separator"> | </span>
                                                        <a
                                                            href={finalPDFUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="fileLinkDocument"
                                                            title="Contrat"
                                                        >
                                                            <span>Contrat</span>
                                                        </a>
                                                    </React.Fragment>
                                                ))}
                                            {documents.slice(0, 2).map((document, i) => (
                                                <React.Fragment key={i}>
                                                    <span className="document-separator"> | </span>
                                                    <a
                                                        href={document.image.contentUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="fileLinkDocument"
                                                        title={document.name}
                                                    >
                                                        <span>{document.name}</span>
                                                    </a>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    )}
                                </Col>
                            )}
                        </>
                    ) : isSupplier ? (
                        <Col md="6" className="text-left">
                            <Button className="btn-white">Réservé aux clients</Button>
                        </Col>
                    ) : (
                        <Col md="6" className="text-center">
                            <Button
                                className="btn-white"
                                onClick={() => dispatch(modalsOperations.getModalStatus("login", true, null))}
                            >
                                Vous devez être connecté(e)
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>
        </Col>
    );
};

export default Supplier;
