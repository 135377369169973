import React from "react";
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {Row, Col, Button} from "reactstrap";
import Layout from "views/universal/Layout/Layout";
import "./About.css";
import fournisseurs from "assets/img/about/fournisseurs.jpg";
import bureau from "assets/img/about/bureau.jpg";
import calendrier from "assets/img/about/calendrier.jpg";
import interet from "assets/img/about/interet.jpg";
import isolation from "assets/img/about/isolation.jpg";
import quisommesnous from "assets/img/about/quisommesnous.jpg";
import FreePriceBloc from "./FreePriceBloc";
import Banner from "views/universal/Banner/Banner";
import {modalsOperations} from "state/ducks/modal";
import MediaBloc from "./MediaBloc";
import picto1 from "assets/img/about/1- Gain de temps.png";
import picto2 from "assets/img/about/2 - cout avantageux.png";
import picto3 from "assets/img/about/3- 3000 prestations.png";
import picto4 from "assets/img/about/4- proximite.png";
import picto5 from "assets/img/about/5- achat responsable.png";

const About = (props) => {
    const dispatch = useDispatch();

    const session = useSelector((state) => state.session, shallowEqual);

    return (
        <Layout>
            <Banner
                titleBanner="Centrale Nationale d'Achat Public <br/>dédiée à la rénovation de patrimoine"
                desc={
                    "Vous êtes soumis au Code de la commande publique ? <br/> Vous souhaitez entretenir ou rénover le patrimoine dont vous avez la responsabilité ?"
                }
            />
            <section className="section-page">
                <section id="section1" className="container section-bloc-about">
                    <Row className="bloc-about mt-4">
                        <Col
                            sm={4}
                            className="p-0"
                            style={{
                                backgroundImage: `url('${fournisseurs}')`,
                                position: "relative",
                                overflow: "hidden",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        ></Col>
                        <Col sm={8} className="align-self-center content-bloc">
                            <div style={{textAlign: "center"}}>
                                <h2 className="title-section">
                                    <strong>Qui</strong> sommes-nous ?
                                </h2>
                            </div>
                            <br/>
                            <div style={{textAlign: "justify"}}>
                                <p>
                                    Créée en 2018 sous forme associative,{" "}
                                    <strong>Centralis®</strong> est une{" "}
                                    <strong>centrale d'achat public</strong> dédiée à la
                                    rénovation et à l’entretien de patrimoine immobilier. Son rôle
                                    et ses modalités d'intervention sont définis par l’article L.
                                    2113-2 du Code de la commande publique.
                                </p>
                                <p>
                                    Plusieurs centaines d’acheteurs répartis sur l’ensemble du
                                    territoire utilisent Centralis. Parmi eux, on compte aussi
                                    bien l’État que des collectivités, des intercommunalités, des
                                    établissements et des offices publics, des syndicats, des
                                    sociétés publiques locales ou des sociétés d'économie mixte.{" "}
                                    <a href="/acheteurs" className="fileLink">
                                        Voir la liste des acheteurs
                                    </a>
                                </p>
                                {!session?.isAuthenticated && (
                                    <p>
                    <span
                        onClick={() =>
                            dispatch(
                                modalsOperations.getModalStatus(
                                    "register",
                                    true,
                                    "big"
                                )
                            )
                        }
                        className="fileLink"
                    >
                      Inscrivez-vous
                    </span>{" "}
                                        pour accéder au catalogue Centralis et effectuer des achats
                                        en ligne, en dispense de procédure, parmi une vingtaine de
                                        catégories de métier. L’inscription est gratuite et sans
                                        engagement.
                                    </p>
                                )}
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="section2" className="container section-bloc-about">
                    <Row className="bloc-about">
                        <Col
                            sm={6}
                            className="align-self-center content-bloc content-bloc"
                            style={{backgroundColor: "#2C3948", color: "white"}}
                        >
                            <div style={{textAlign: "center"}}>
                                <h2 className="title-section">
                                    La <strong>mutualisation</strong> des achats
                                </h2>
                            </div>
                            <br/>
                            <div style={{textAlign: "justify"}}>
                                <p>
                                    <i>
                                        « Les acheteurs qui recourent à une centrale d’achat pour la
                                        réalisation de travaux ou l’acquisition de services sont
                                        considérés comme ayant respecté leurs obligations de
                                        publicité et de mise en concurrence »
                                    </i>
                                    . Article L2113-4 du Code de la commande publique.{" "}
                                </p>
                            </div>
                        </Col>

                        <Col
                            sm={6}
                            className="p-0 content-bloc-img"
                            style={{
                                backgroundImage: `url('${bureau}')`,
                                position: "relative",
                                overflow: "hidden",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        ></Col>
                        <Col sm={12}>
                            <br/>
                            <table className="table-responsive">
                                <thead>
                                <tr>
                                    <th
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            width: "20%",
                                        }}
                                    >
                                        <img
                                            src={picto1}
                                            alt="Logos avantage centralis"
                                            style={{maxWidth: "80px"}}
                                        />
                                        <h6>
                                            <strong>Gain de temps</strong>
                                        </h6>
                                    </th>
                                    <th
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            width: "20%",
                                        }}
                                    >
                                        <img
                                            src={picto2}
                                            alt="Logos avantage centralis"
                                            style={{maxWidth: "80px"}}
                                        />
                                        <h6>
                                            <strong>Coûts avantageux</strong>
                                        </h6>
                                    </th>
                                    <th
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            width: "20%",
                                        }}
                                    >
                                        <img
                                            src={picto3}
                                            alt="Logos avantage centralis"
                                            style={{maxWidth: "80px"}}
                                        />
                                        <h6>
                                            <strong>290 000 Prestations</strong>
                                        </h6>
                                    </th>
                                    <th
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            width: "20%",
                                        }}
                                    >
                                        <img
                                            src={picto4}
                                            alt="Logos avantage centralis"
                                            style={{maxWidth: "80px"}}
                                        />
                                        <h6>
                                            <strong>Proximité</strong>
                                        </h6>
                                    </th>
                                    <th
                                        style={{
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            width: "20%",
                                        }}
                                    >
                                        <img
                                            src={picto5}
                                            alt="Logos avantage centralis"
                                            style={{maxWidth: "80px"}}
                                        />
                                        <h6>
                                            <strong>Achats responsables</strong>
                                        </h6>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{verticalAlign: "baseline", width: "20%"}}>
                                        <p style={{textAlign: "center"}}>
                                            Dispensez-vous des procédures de passation, gagner du
                                            temps et commandez directement
                                        </p>
                                    </td>
                                    <td style={{verticalAlign: "baseline", width: "20%"}}>
                                        <p style={{textAlign: "center"}}>
                                            Bénéficiez de la mutualisation des achats et faites
                                            baisser vos coûts
                                        </p>
                                    </td>
                                    <td style={{verticalAlign: "baseline", width: "20%"}}>
                                        <p style={{textAlign: "center"}}>
                                            Choisissez dans un catalogue parmi plus de 290 000
                                            prestations
                                        </p>
                                    </td>
                                    <td style={{verticalAlign: "baseline", width: "20%"}}>
                                        <p style={{textAlign: "center"}}>
                                            L’ensemble du territoire est couvert par des
                                            prestataires souvent situés à proximité des acheteurs
                                        </p>
                                    </td>
                                    <td style={{verticalAlign: "baseline", width: "20%"}}>
                                        <p style={{textAlign: "center"}}>
                                            Seulement les meilleures offres de prestataires qui ont
                                            tous des engagements environnementaux à respecter
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </section>

                <section id="section3" className="container section-bloc-about">
                    <Row className="bloc-about">
                        <Col
                            sm={2}
                            className="p-0 content-bloc-img"
                            style={{
                                backgroundImage: `url('${isolation}')`,
                                position: "relative",
                                overflow: "hidden",
                                backgroundSize: "cover",
                            }}
                        ></Col>
                        <Col sm={10} className="align-self-center content-bloc">
                            <div style={{textAlign: "center"}}>
                                <h2 className="title-section">
                                    Plusieurs milliers de prestations <br/>
                                    <strong>disponibles immédiatement</strong>
                                </h2>
                            </div>
                            <br/>
                            <Row>
                                <Col sm={6}>
                                    <ul style={{listStyle: "none"}}>
                                        <li>101 - Assistance à Maîtrise d’ouvrage territoriale</li>
                                        <li>102 - Maitrise d’œuvre Bâtiment</li>
                                        <li>103 - Urbaniste - Paysagiste</li>
                                        <li>104 - Bureau d'Etude Voirie et Réseaux Divers</li>
                                        <li>105 - Bureau de contrôle</li>
                                        <li>106 - Coordonnateur Sécurité et Protection Santé</li>
                                        <li>107 - Ordonnancement Pilotage et Coordination</li>
                                        <li>108 - Géomètre</li>
                                        <li>109 - Bureau d'Etude Géotechnique</li>
                                        <li>110 - Bureau d'Etude Environnement</li>
                                        <li>111 - Bureau d'Etude Pollution</li>
                                        <li>
                                            112 - Diagnostiqueur Technique, Energie, Amiante et Plomb
                                        </li>
                                        <li>
                                            113 - Numérisation du patrimoine – BIM
                                        </li>
                                    </ul>
                                </Col>
                                <Col sm={6}>
                                    <ul style={{listStyle: "none"}}>
                                        <li>201 - Démolition - Désamiantage</li>
                                        <li>202 - Gros œuvre, Maçonnerie et Façade</li>
                                        <li>203 - Charpente - Étanchéité - Couverture - Bardage</li>
                                        <li>
                                            204 - Cloison – Faux-plafonds - Plâtrerie – Isolation
                                        </li>
                                        <li>205 - Menuiserie et Serrurerie</li>
                                        <li>206 - Courant fort - Courant faible – Domotique</li>
                                        <li>207- Chauffage - Ventilation – Plomberie</li>
                                        <li>208 - Peinture et revêtements</li>
                                        <li>209 - Espaces verts</li>
                                        <li>210 - Voirie et réseaux divers</li>
                                        <li>211 - Nettoyage</li>
                                        <li>212 - Multiservices et performances énergétiques</li>
                                    </ul>
                                </Col>
                                <Col sm={12}>
                                    <br/>
                                    <p>
                                        <strong>
                                            Le catalogue issu de procédures formalisées :
                                        </strong>{" "}
                                        Dans le cadre précis de nos accords-cadres
                                        multi-attributaires, à bons de commande et à marchés
                                        subséquents notifiés aux titulaires, vous pouvez commander
                                        sans délai diverses prestations en dispense totale de
                                        procédure.{" "}
                                        <a href="/catalogue" className="fileLink">
                                            Voir le catalogue formalisé
                                        </a>
                                    </p>
                                    <p>
                                        <strong>
                                            Le catalogue issu d’un référencement libre :
                                        </strong>{" "}
                                        Dans cet espace créé par les entreprises qui proposent
                                        librement leurs prestations, il appartient à chaque acheteur
                                        de vérifier le profil de l’entreprise et de respecter les
                                        seuils d’achat ainsi que les règles de passation imposées
                                        par le Code de la commande publique.{" "}
                                        <a href="/catalogue/libre" className="fileLink">
                                            Voir le catalogue libre
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

                <section id="section5" className="container section-bloc-about">
                    <Row className="bloc-about">
                        <Col
                            sm={8}
                            className="align-self-center content-bloc content-bloc"
                            style={{backgroundColor: "#DB7124", color: "white"}}
                        >
                            <div style={{textAlign: "center"}}>
                                <h2 className="title-section" style={{color: "white"}}>
                                    Des <strong>entreprises performantes</strong> sur tout le
                                    territoire
                                </h2>
                            </div>
                            <div>
                                <p>
                                    Toute entreprise liée à l’objet de Centralis a la possibilité
                                    de se faire référencer pour proposer ses prestations dans
                                    notre « catalogue libre » à tout moment.
                                </p>
                                <p>
                                    En ce qui concerne le « catalogue formalisé », afin de
                                    permettre à un maximum d’entreprises d’accéder à nos
                                    procédures formalisées, nos accords-cadres sont allotis par
                                    métier et par secteur géographique (échelle départementale
                                    pour les prestataires de travaux, échelle régionale pour les
                                    prestataires intellectuels).
                                </p>
                                <p style={{textAlign: "center"}}>
                                    <a href="/prestataires/libre">
                                        <Button className="btn-white">
                                            Voir l’annuaire des prestataires
                                        </Button>
                                    </a>
                                </p>
                                <p>
                                    Vous souhaitez devenir prestataire ? Consultez nos annonces
                                    légales sur les sites BOAMP et JOUE en août et septembre de
                                    chaque année paire. Il convient ensuite de respecter le
                                    règlement de consultation.
                                </p>
                            </div>
                        </Col>
                        <Col
                            sm={4}
                            className="p-0 content-bloc-img"
                            style={{
                                backgroundImage: `url('${quisommesnous}')`,
                                position: "relative",
                                overflow: "hidden",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        ></Col>
                    </Row>
                </section>

                <section id="section7" className="container section-bloc-about">
                    <Row className="bloc-about">
                        <Col
                            sm={10}
                            className="align-self-center content-bloc content-bloc-white"
                        >
                            <div style={{textAlign: "center"}}>
                                <h2 className="title-section">
                                    Un fonctionnement <strong>simple</strong>
                                </h2>
                            </div>
                            <div>
                                <table className="table table-about">
                                    <thead>
                                    <tr>
                                        <th
                                            style={{
                                                background: "#DB7124",
                                                textAlign: "center",
                                                color: "white",
                                                border: "5px white solid",
                                            }}
                                        >
                                            <h4>
                                                1<br/>
                                                Séléction
                                            </h4>
                                        </th>
                                        <th
                                            style={{
                                                background: "#DB7124",
                                                textAlign: "center",
                                                color: "white",
                                                border: "5px white solid",
                                            }}
                                        >
                                            <h4>
                                                2<br/>
                                                Commande
                                            </h4>
                                        </th>
                                        <th
                                            style={{
                                                background: "#DB7124",
                                                textAlign: "center",
                                                color: "white",
                                                border: "5px white solid",
                                            }}
                                        >
                                            <h4>
                                                3<br/>
                                                Paiement
                                            </h4>
                                        </th>
                                        <th
                                            style={{
                                                background: "#DB7124",
                                                textAlign: "center",
                                                color: "white",
                                                border: "5px white solid",
                                            }}
                                        >
                                            <h4>
                                                4<br/>
                                                Délais
                                            </h4>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{verticalAlign: "baseline"}}>
                                            <p style={{textAlign: "left"}}>
                                                Après avoir précisé le lieu de l’opération,
                                                <br/>
                                                <br/>
                                                Soit je <strong>
                                                sélectionne les prestations
                                            </strong>{" "}
                                                qui m’intéressent et les ajoute à mon panier.
                                                <br/>
                                                <br/>
                                                Soit je demande à l’entreprise titulaire de rang 1 de
                                                me transmettre un <strong>panier prérempli</strong> ,
                                                à vérifier avant validation.
                                                <br/>
                                                <br/>
                                                Si je ne trouve pas ce qui me convient dans le
                                                catalogue, je peux lancer un{" "}
                                                <strong>marché subséquent</strong> et attendre les
                                                offres des titulaires.
                                            </p>
                                        </td>
                                        <td style={{verticalAlign: "baseline"}}>
                                            <p style={{textAlign: "left"}}>
                                                L’administrateur de mon compte{" "}
                                                <strong>valide le panier</strong>. La centrale{" "}
                                                <strong>envoie les bons de commande</strong> aux
                                                différentes entreprises concernées qui disposent de 10
                                                jours pour <strong>accepter</strong> ou refuser{" "}
                                                <strong>la mission</strong>.<br/>
                                                <br/>
                                                En cas de refus et avec votre accord, la commande est
                                                transmise à{" "}
                                                <strong>l’entreprise de rang suivant</strong>.
                                            </p>
                                        </td>
                                        <td style={{verticalAlign: "baseline"}}>
                                            <p style={{textAlign: "left"}}>
                                                Les entreprises retenues interviennent dans le cadre
                                                contractuel et me{" "}
                                                <strong>facturent leurs prestations</strong>{" "}
                                                directement via <strong>Chorus Pro</strong>, sans
                                                transiter par Centralis. Je garde un{" "}
                                                <strong>contrôle total sur le paiement</strong>.<br/>
                                                <br/>
                                                Si l’entreprise souhaite utiliser des{" "}
                                                <strong>sous-traitants</strong>, elle me fait passer
                                                le DC4 pour approbation.
                                            </p>
                                        </td>
                                        <td style={{verticalAlign: "baseline"}}>
                                            <p style={{textAlign: "left"}}>
                                                Chaque entreprise est tenue de respecter le{" "}
                                                <strong>délai d’exécution contractuel</strong> –
                                                éventuellement modifié par dérogation – faute de quoi
                                                des pénalités peuvent s’appliquer.
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col
                            sm={2}
                            className="p-0 content-bloc-img"
                            style={{
                                backgroundImage: `url('${interet}')`,
                                position: "relative",
                                overflow: "hidden",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        ></Col>
                    </Row>
                </section>

                <section id="section6" className="container section-bloc-about">
                    <Row className="bloc-about">
                        <Col
                            sm={4}
                            className="p-0 content-bloc-img"
                            style={{
                                backgroundImage: `url('${calendrier}')`,
                                position: "relative",
                                overflow: "hidden",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        ></Col>
                        <Col
                            sm={8}
                            className="align-self-center content-bloc content-bloc"
                            style={{backgroundColor: "#5e5c5c", color: "white"}}
                        >
                            <div style={{textAlign: "center"}}>
                                <h2 className="title-section">
                                    Un <strong>délai</strong> d’exécution{" "}
                                    <strong>maîtrisé</strong>
                                </h2>
                            </div>
                            <div style={{textAlign: "center"}}>
                                <p>
                                    1 journée par tranche de 1.000€ de prestations d’ingénierie
                                    commandées
                                    <br/>1 journée par tranche de 1.500€ de prestations travaux
                                    commandées
                                </p>
                                <p>
                                    Exemple pour une rénovation thermique par l’extérieur d’un
                                    bâtiment :
                                </p>
                                <h4>
                                    <strong>
                                        Commande de 42.000€ = Réalisation en 28 jours ouvrés
                                    </strong>
                                </h4>
                                <br/>
                                <p>
                                    Pour chaque bon de commande, il est possible de déroger à la
                                    règle si nécessaire.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </section>

                <FreePriceBloc/>

                <MediaBloc/>

            </section>
        </Layout>
    );
};

export default About;
