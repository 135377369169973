import React from "react";
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom'
import LotsDetails from './LotsDetails'
import { useDispatch } from "react-redux";

import { storeOperations } from "state/ducks/store";

const Lots = (props) => {

  const dispatch = useDispatch();

  const {
    departmentCode,
    lots,
    filters,
    isAuthenticated,
    cartSelector,
    user
  } = props
  return (

    <section className="p-section">
      <Container>
        {(filters.category === 'all' && filters.prestation === '') ?
          <Row>
            <style>
              {`
          .lot-container:hover .lot-text {
            color: orange;
          }
        `}
            </style>
            {lots.map((lot) => {
              return (
                <Col md="3" key={lot.code} style={{ paddingLeft: '0', paddingRight: '0' }}>
                  <Link to={{
                    pathname: `/catalogue`,
                    search: 'lot=' + lot.code
                  }} onClick={() => {
                    dispatch(storeOperations.filtersLots(lot.code, filters.prestation));
                    window.scrollTo(0, 0);
                  }}>
                    <span className="lot-container" style=
                      {{
                        backgroundColor: 'black',
                        background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('${lot.image.contentUrl}')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        textAlign: 'center',
                        alignItems: 'center',
                        fontSize: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '300px',
                        margin: '5px',
                        color: 'white',
                        hover: 'color: red'
                      }}>
                      <span className="lot-text">
                        {lot.name}
                      </span>
                    </span>
                  </Link>
                </Col>
              )
            })}
          </Row>
          :
          <LotsDetails
            departmentCode={departmentCode}
            lots={lots}
            filters={filters}
            isAuthenticated={isAuthenticated}
            cartSelector={cartSelector}
            user={user}
          />
        }
      </Container>
    </section>
  );
};

Lots.propTypes = {};

Lots.defaultProps = {};

export default Lots;
