import React, {useState} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {Row, Col, Input, FormGroup} from 'reactstrap';
import {Link} from 'react-router-dom'
import Account from "./../Account";
import BlocInfos from "views/universal/BlocInfos/BlocInfos"
import IconPanier from 'assets/img/icon-panier.svg';
import IconTrash from "assets/img/icon_trash.svg";
import moment from "moment/moment";
import 'moment/locale/fr';
import {centimesToCurrency} from 'utils/Utils'
import {sessionOperations} from 'state/ducks/session'

const Carts = (props) => {

    const dispatch = useDispatch();

    const session = useSelector(
        (state) => state.session,
        shallowEqual
    )
    const [cartFilter, setCartFilter] = useState('all'); // 'all', 'formalized', 'free'


    const renderBlocDetails = (value) => {
        const blocPanier = document.querySelector(`#c${value}`);
        blocPanier.style.display = (blocPanier.style.display === 'block') ? 'none' : 'block';
    }

    const removeCart = (cartId, cartType) => {
        if (window.confirm("Voulez-vous vraiment supprimer ce panier ?")) {
            dispatch(sessionOperations.removeCart(cartId, cartType))
        }
    }

    const renderNotSentPanier = (panier, cartType) => {
        return (
            <div key={panier.id} className="bloc-panier">
                <Row style={{marginBottom: "30px"}}>
                    <Col md={2} style={{display: 'flex'}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {cartType === 'free'
                                ?
                                <div className="statut-free">libre</div>
                                :
                                <div className="statut-formalized">formalisé</div>
                            }
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span>{moment(panier.date).locale('fr').format('L')}</span>

                        </div>
                    </Col>
                    <Col md={3} style={{textAlign: "right"}}>
                        <span style={{color: "#2C3948"}}>{centimesToCurrency(panier.price)}
                        </span>
                    </Col>
                    <Col md={3}>
                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            height: "100%",
                            justifyContent: "flex-end"
                        }}>
                            {cartType === 'free'
                                ?
                                <Link to={{pathname: `/compte/panier/libre/${panier.id}`}}>
                                    <button className="btn-green float-right"> Voir le panier</button>
                                </Link>
                                :
                                <Link to={{pathname: `/compte/panier/${panier.id}`}}>
                                    <button className="btn-green float-right"> Voir le panier</button>
                                </Link>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} style={{display: "flex"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className="statut-cart">À valider</div>
                        </div>
                    </Col>
                    <Col md={7} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{panier.name}</b>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3}>

                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            height: "100%",
                            justifyContent: "flex-end"
                        }}>
                            <button className="btn-green float-right"
                                    onClick={() => removeCart(panier.id, cartType)}>
                                <img height="auto" style={{width: "15px"}} src={IconTrash} alt="Icon Trash"/>
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderDetails = (orderLotSupplier) => {
        return (
            <div id={'c' + orderLotSupplier.id} className="bloc-panier-table" style={{marginTop: "20px"}}>
                <div className="bloc-panier-header">
                    <div>N°Bon De Commande</div>
                    <div>Statut</div>
                    <div>Département</div>
                    <div className="bloc-panier-header-lot">Lot</div>
                    <div>Total HT</div>
                    {(orderLotSupplier.status === 1 || orderLotSupplier.status === 2) &&
                        <div>Lien</div>
                    }
                </div>
                <div className="bloc-panier-content">
                    <div>
                        <div className="bloc-panier-content-lot">
                            <div className="bloc-panier-content-lot-header">
                                <div>
                                    <p>{orderLotSupplier.status > 0 && orderLotSupplier.number}</p>
                                </div>

                                <div>
                                    <p>{orderLotSupplier.status > 0 && orderLotSupplier.statusTextFR}</p>
                                </div>

                                <div>{orderLotSupplier.postal}</div>
                                <div
                                    className="bloc-panier-content-lot-header-lot">{orderLotSupplier.lotCode} – {orderLotSupplier.lotName}</div>

                                <div>
                                    <p>{orderLotSupplier.status > 0 && centimesToCurrency(orderLotSupplier.price)}</p>
                                </div>
                                {(orderLotSupplier.status === 1 || orderLotSupplier.status === 2) &&
                                    <div><a href={orderLotSupplier.publicUrl && orderLotSupplier.publicUrl}
                                            target="_blank" rel="noopener noreferrer">Résumé & bons de commande</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const renderOrder = (orderLotSupplier, type) => {
        return (
            <div key={orderLotSupplier.id} className="bloc-panier">
                <Row style={{marginBottom: "30px"}}>
                    <Col md={2} style={{display: 'flex'}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {type === 'free' ? <div className="statut-free">Libre</div> :
                                <div className="statut-formalized">Formalisé</div>}

                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span>{moment(orderLotSupplier.date).locale('fr').format('L')}</span>

                        </div>
                    </Col>
                    <Col md={3} style={{textAlign: "right"}}>
                        <span style={{color: "#2C3948"}}>{centimesToCurrency(orderLotSupplier.price)}
                        </span>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} style={{display: "flex"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className="statut-cart">{orderLotSupplier.statusTextFR}</div>
                        </div>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{orderLotSupplier.cartName}</b>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{orderLotSupplier.customerName}</b>
                            </li>
                            {/*<li>Montant Total HT <b style={{color: "#2C3948"}}>{panier.customer}</b>*/}
                            {/*</li>*/}
                        </ul>
                    </Col>
                    <Col md={3}>

                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            height: "100%",
                            justifyContent: "flex-end"
                        }}>
                            <button
                                onClick={() => renderBlocDetails(orderLotSupplier.id)}
                                className="btn-green float-right">
                                Voir plus
                                {/*<img src={arrowPanier} alt="flèche produit"/>*/}
                            </button>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>
                        {orderLotSupplier !== null ? renderDetails(orderLotSupplier) : null}
                    </Col>

                </Row>

            </div>
        )

    }

    const renderPaniers = () => {
        if (session.pendingCarts.length < 1 && session.confirmedCarts.length < 1 && session.confirmedFreeCarts.length < 1 && session.pendingFreeCarts.length < 1) {
            return <BlocInfos title="Vous n'avez aucun panier" icon={IconPanier}/>
        } else {
            return (<>
                    <Row>
                        <Col lg="4">
                            <FormGroup className="mb-3">
                                <Input
                                    type="select"
                                    name="selectType"
                                    id="selectType"
                                    value={cartFilter}
                                    onChange={(e) => setCartFilter(e.target.value)}>

                                    <option value="all">Tous les paniers</option>
                                    <option value="formalized">Formalisé</option>
                                    <option value="free">Libre</option>


                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*<span>{session.confirmedCarts && session.confirmedCarts.length > 0 && session.confirmedCarts.map((cart) => (renderSentPanier(cart, 'formalized')))}</span>*/}
                    {/*<span>{session.pendingCarts && session.pendingCarts.length > 0 && session.pendingCarts.map((cart) => (renderNotSentPanier(cart, 'formalized')))}</span>*/}
                    {/*<span>{session.confirmedFreeCarts && session.confirmedFreeCarts.length > 0 && session.confirmedFreeCarts.map((cart) => (renderSentPanier(cart, 'free')))}</span>*/}
                    {/*<span>{session.pendingFreeCarts && session.pendingFreeCarts.length > 0 && session.pendingFreeCarts.map((cart) => (renderNotSentPanier(cart, 'free')))}</span>*/}
                    {/*<span>{session.orderLotSuppliers && session.orderLotSuppliers.length > 0 && session.orderLotSuppliers.map((orderLotSupplier, key) => (<span key={key}>{renderOrder(orderLotSupplier, "formalized")}</span>))}</span>*/}
                    {/*<span>{session.orderFreeSuppliers && session.orderFreeSuppliers.length > 0 && session.orderFreeSuppliers.map((orderFreeSuppliers, key) => (<span key={key}>{renderOrder(orderFreeSuppliers, "free")}</span>))}</span>*/}


                    {cartFilter === 'formalized' && session.pendingCarts && session.pendingCarts.length > 0 && session.pendingCarts.map((cart) => (renderNotSentPanier(cart, 'formalized')))}
                    {/*{cartFilter === 'formalized' && session.confirmedCarts && session.confirmedCarts.length > 0 && session.confirmedCarts.map((cart) => (renderSentPanier(cart, 'formalized')))}*/}
                    {cartFilter === 'formalized' && session.orderLotSuppliers && session.orderLotSuppliers.length > 0 && session.orderLotSuppliers.map((orderLotSupplier, key) => (
                        <span key={key}>{renderOrder(orderLotSupplier, "formalized")}</span>))}
                    {cartFilter === 'free' && session.pendingFreeCarts && session.pendingFreeCarts.length > 0 && session.pendingFreeCarts.map((cart) => (renderNotSentPanier(cart, 'free')))}
                    {/*{cartFilter === 'free' && session.confirmedFreeCarts && session.confirmedFreeCarts.length > 0 && session.confirmedFreeCarts.map((cart) => (renderSentPanier(cart, 'free')))}*/}
                    {cartFilter === 'free' && session.orderFreeSuppliers && session.orderFreeSuppliers.length > 0 && session.orderFreeSuppliers.map((orderFreeSuppliers, key) => (
                        orderFreeSuppliers.status !== 0 &&
                        <span key={key}>{renderOrder(orderFreeSuppliers, "free")}</span>))}
                    {cartFilter === 'all' && session.pendingCarts && session.pendingCarts.length > 0 && session.pendingCarts.map((cart) => (renderNotSentPanier(cart, 'formalized')))}
                    {cartFilter === 'all' && session.pendingFreeCarts && session.pendingFreeCarts.length > 0 && session.pendingFreeCarts.map((cart) => (renderNotSentPanier(cart, 'free')))}
                    {/*{cartFilter === 'all' && session.confirmedCarts && session.confirmedCarts.length > 0 && session.confirmedCarts.map((cart) => (renderSentPanier(cart, 'formalized')))}*/}
                    {/*{cartFilter === 'all' && session.confirmedFreeCarts && session.confirmedFreeCarts.length > 0 && session.confirmedFreeCarts.map((cart) => (renderSentPanier(cart, 'free')))}*/}
                    {cartFilter === 'all' && session.orderLotSuppliers && session.orderLotSuppliers.length > 0 && session.orderLotSuppliers.map((orderLotSupplier, key) => (
                        <span key={key}>{renderOrder(orderLotSupplier, "formalized")}</span>))}
                    {cartFilter === 'all' && session.orderFreeSuppliers && session.orderFreeSuppliers.length > 0 && session.orderFreeSuppliers.map((orderFreeSuppliers, key) => (
                        orderFreeSuppliers.status !== 0 &&
                        <span key={key}>{renderOrder(orderFreeSuppliers, "free")}</span>))}

                </>
            )
        }
    }

    return (
        <Account>
            {renderPaniers()}
        </Account>
    );
};

Carts.propTypes = {};

Carts.defaultProps = {};

export default Carts;
