import {
    Dev,
    Customers,
    Suppliers,
    MyInfos,
    Cart,
    Carts,
    ConfirmedCarts,
    About,
    Downloads,
    Page,
    NotFound,
    SupplierMyInfos,
    SupplierCarts,
    FreeCart,
    SupplierArticles,
    Catalog,
    FormSupplier,
    FormSupplierPhase2,
    SupplierAccounts,
    SupplierDownloads,
    Orders,
    FreeOrders,
    AccountOrders,
    CustomerAccounts,
    CustomerAccountCarts,
    ConfirmedFreeCarts,
    FreeCatalog,
    FreeCatalogSupplier,
    RetrieveCart,

} from "views/pages/";

const routes = [
    {
        path: "/dev",
        component: Dev,
        private: false,
    },
    {
        path: "/catalogue",
        component: Catalog,
        private: false,
    },
    {
        path: "/catalogue/libre",
        component: FreeCatalog,
        private: false,
    },
    {
        path: "/catalogue/libre/:supplierId",
        component: FreeCatalogSupplier,
        supplierId: ":supplierId",
        private: false,
    },
    {
        path: "/prestataires/:type",
        component: Suppliers,
        type: ":type",
        private: false,
    },
    {
        path: "/acheteurs",
        component: Customers,
        private: false,
    },
    {
        path: "/compte/informations",
        component: MyInfos,
        private: true,
        roles: ["ROLE_CUSTOMER"],
    },
    {
        path: "/documentation/:type",
        component: Downloads,
        type: ":type",
        private: true,
        roles: ["ROLE_CUSTOMER", "ROLE_SUPPLIER"],
    },
    // C'est important de mette les routes dans l'ordre, sinon ça ne marche pas pour /compte/paniers/valides qui lancera en réalité /compte/paniers/:viewType
    {
        path: "/compte/paniers/valides",
        component: ConfirmedCarts,
        private: true,
        roles: ["ROLE_CUSTOMER"],
    },
    // {
    //     path: "/compte/retrieve",
    //     component: RetrieveCart,
    //     private: true,
    //     roles: ["ROLE_CUSTOMER", "ROLE_SUPPLIER"],
    // },
    {
        path: "/compte/paniers/libres/valides",
        component: ConfirmedFreeCarts,
        private: true,
        roles: ["ROLE_CUSTOMER"],
    },
    {
        path: "/compte/paniers",
        component: Carts,
        private: true,
        roles: ["ROLE_CUSTOMER"],
    },
    {
        path: "/compte/paniers/:viewType",
        component: Carts,
        private: true,
        roles: ["ROLE_CUSTOMER"],
    },
    {
        path: "/compte/panier/:id",
        component: Cart,
        cartId: ":id",
        private: true,
        roles: ["ROLE_CUSTOMER", "ROLE_SUPPLIER"],
    },

    {
        path: "/compte/comptes",
        component: CustomerAccounts,
        private: true,
        roles: ["ROLE_SUPER_CUSTOMER"],
    },
    {
        path: "/compte/comptes/:customerId/paniers",
        component: CustomerAccountCarts,
        compteId: ":customerId",
        private: true,
        roles: ["ROLE_SUPER_CUSTOMER"],
    },
    {
        path: "/",
        component: About,
        private: false,
    },
    {
        path: "/mentions-legales",
        component: Page,
        private: false,
    },
    {
        path: "/conditions-generales",
        component: Page,
        private: false,
    },
    {
        path: "/faq",
        component: Page,
        private: false,
    },
    {
        path: "/compte/fournisseur/informations",
        component: SupplierMyInfos,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/paniers",
        component: SupplierCarts,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/articles",
        component: SupplierArticles,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/comptes",
        component: SupplierAccounts,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/documents",
        component: SupplierDownloads,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/comptes/:supplierId/commandes",
        component: AccountOrders,
        compteId: ":supplierId",
        private: true,
        roles: ["ROLE_SUPER_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/commandes",
        component: Orders,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/commandes/libres",
        component: FreeOrders,
        private: true,
        roles: ["ROLE_SUPPLIER"],
    },
    {
        path: "/compte/fournisseur/documents",
        component: SupplierDownloads,
        private: true,
        roles: ["ROLE_SUPER_SUPPLIER"],
    },
    {
        path: "/compte/panier/libre/:id",
        component: FreeCart,
        cartId: ":id",
        private: true,
        roles: ["ROLE_CUSTOMER", "ROLE_SUPPLIER"],
    },
    {
        path: "/candidature",
        component: FormSupplier,
        private: false,
    },
    {
        path: "/depot-offre",
        component: FormSupplierPhase2,
        private: false,
    },
    {
        path: "/depot-offre/:viewMode/:token",
        component: FormSupplierPhase2,
        viewMode: ":viewMode",
        token: ":token",
        private: false,
    },
    {
        path: "*",
        component: NotFound,
    },
];

export default routes;
