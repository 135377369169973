import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import { sessionOperations } from "state/ducks/session";

const Account = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const supersupplier = useSelector(
    (state) => state.supersupplier,
    shallowEqual
  );

  const general = useSelector((state) => state.general, shallowEqual);

  return (
    <Layout>
      {(session.isLoading || supersupplier.isLoading || general.isLoading) && (
        <GlobalLoader message={"Chargement en cours ..."} />
      )}
      <Container style={{ margin: "25px auto" }}>
        <Row>
          <Col md={3}>
            <ul className="nav-account">
              <li>
                <Link
                  to="/compte/fournisseur/paniers"
                  className={
                    location.pathname === "/compte/fournisseur/paniers"
                      ? "active"
                      : null
                  }
                >
                  Mes paniers
                </Link>
              </li>
              {/*{session.user.type === "formalized" && (*/}
              {/*  <li>*/}
              {/*    <Link*/}
              {/*      to="/compte/fournisseur/commandes"*/}
              {/*      className={*/}
              {/*        location.pathname === "/compte/fournisseur/commandes"*/}
              {/*          ? "active"*/}
              {/*          : null*/}
              {/*      }*/}
              {/*    >*/}
              {/*      Mes commandes formalisées*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              {/*<li>*/}
              {/*  <Link*/}
              {/*    to="/compte/fournisseur/commandes/libres"*/}
              {/*    className={*/}
              {/*      location.pathname === "/compte/fournisseur/commandes/libres"*/}
              {/*        ? "active"*/}
              {/*        : null*/}
              {/*    }*/}
              {/*  >*/}
              {/*    Mes commandes libres*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link
                  to="/compte/fournisseur/articles"
                  className={
                    location.pathname === "/compte/fournisseur/articles"
                      ? "active"
                      : null
                  }
                >
                  Mon catalogue Libre
                </Link>
              </li>
              <li>
                <Link
                  to={"/compte/fournisseur/informations"}
                  className={
                    location.pathname === "/compte/fournisseur/informations" || location.pathname.startsWith(
                      "/compte/fournisseur/comptes"
                    )
                      ? "active"
                      : null
                  }
                >
                  Mon profil
                </Link>
              </li>
              {session.user.roles.includes("ROLE_SUPPLIER") && (
                <li>
                  <Link
                    to={"/compte/fournisseur/documents"}
                    className={
                      location.pathname.startsWith(
                        "/compte/fournisseur/documents"
                      )
                        ? "active"
                        : null
                    }
                  >
                    Mes documents
                  </Link>
                </li>
              )}
              {/* {session.user.roles.includes("ROLE_SUPER_SUPPLIER") &&
                session.user.type === "formalized" && (
                  <li>
                    <Link
                      to={"/compte/fournisseur/comptes"}
                      className={
                        location.pathname.startsWith(
                          "/compte/fournisseur/comptes"
                        )
                          ? "active"
                          : null
                      }
                    >
                      Gestion des utilisateurs
                    </Link>
                  </li>
                )} */}
              <li
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(sessionOperations.logout())}
              >
                Déconnexion
              </li>
            </ul>
          </Col>
          <Col md={9}>{props.children}</Col>
        </Row>
      </Container>
    </Layout>
  );
};

Account.propTypes = {};

Account.defaultProps = {};

export default Account;
