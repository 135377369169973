import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Row, Col, FormGroup, Input, Label, Container } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Card from "@material-ui/core/Card";
import { sessionOperations } from "state/ducks/session";
import moment from "moment/moment";
import "moment/locale/fr";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useHistory } from "react-router-dom";
import IconTrash from "../../../../assets/img/icon-trash-black.svg";
import { modalsOperations } from "../../../../state/ducks/modal";
import { centimesToCurrency } from "../../../../utils/Utils";
import swal from "sweetalert";

registerLocale("fr", fr);

const AddressForm = (props) => {
    const { cart, seeAsSuper, user } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [SendCart, setSendCart] = useState(false);
    const [submitAction, setSubmitAction] = useState('');
    const session = useSelector((state) => state.session, shallowEqual);

    useEffect(() => {
        const intentionToOrder = localStorage.getItem('intentionToOrder');
        const intentionToPrint = localStorage.getItem('intentionToPrint');

        if (intentionToOrder === 'true') {
            confirmCart();
            localStorage.removeItem('intentionToOrder');
        }
        if (intentionToPrint === 'true') {
            dispatch(sessionOperations.printCart(cart.id));
            localStorage.removeItem('intentionToPrint');
        }
    }, []);


    const removeCart = () => {
        if (window.confirm("Voulez-vous vraiment supprimer ce panier ?")) {
            dispatch(sessionOperations.removeCart(cart.id, "formalized")).then(
                (success) => {
                    if (session.user.roles.includes("ROLE_SUPPLIER")) {
                        return history.push("/compte/fournisseur/paniers");
                    }
                    history.push("/compte/paniers");
                    window.location.reload();
                    return;
                }
            );
        }
    };

    const isSupplier =
        !!(session?.user?.roles && session.user.roles.includes("ROLE_SUPPLIER"));

    let canEditCart = false;
    if (isSupplier) {
        if (cart.status === 5) {
            canEditCart = true;
        } else {
            canEditCart = false;
        }
    } else {
        canEditCart = !cart.supplier;
    }

    if (seeAsSuper) {
        canEditCart = false;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: cart.address !== null ? cart.address : "",
            customerReferenceNumber:
                cart.customerReferenceNumber !== null
                    ? cart.customerReferenceNumber
                    : "",
            code: cart.department.code,
            message: cart.message !== null ? cart.message : "",
            modalite: cart.modalite !== null ? cart.modalite : "",
            operationEndingDate: cart.operationEndingDate
                ? moment(cart.operationEndingDate).format("YYYY-MM-DD")
                : null,
            moContact: cart.moContact !== null ? cart.moContact : "",
            registerCgu: cart.address != null,
        },
        validationSchema: Yup.object({
            address: Yup.string().required("Ce champ est requis"),
            code: Yup.string().required("Ce champ est requis"),
            registerCgu: Yup.bool().oneOf([true], "Vous devez accepter nos CGU"),
            message: Yup.string().required("Ce champ est requis"),
        }),
        onSubmit: async (values) => {
            try {
                if (submitAction === 'confirm') {
                    localStorage.setItem('intentionToOrder', 'true');
                }
                if (submitAction === 'print') {
                    localStorage.setItem('intentionToPrint', 'true');
                }
                await dispatch(sessionOperations.updateCart(cart.id, values));

            } catch (error) {
                window.alert('Erreur lors de la sauvegarde des informations du panier. Veuillez réessayer.');
            }
        },
    });


    const cartId = cart.postal + cart.id + "F";
    const confirmCart = () => {
        if (
            user &&
            user.email &&
            user.roles &&
            user.roles.includes("ROLE_SUPPLIER")
        ) {
            dispatch(
                modalsOperations.getModalStatus(
                    "send_cart_to_customer",
                    true,
                    "big",
                    null,
                    {
                        cartId: cartId,
                        cartType: "formalized",
                        email: cart?.proposalEmail,
                        cartAddress: cart.address,
                        cartCountArticles: cart.countArticles,
                    }
                )
            );
        } else if (!cart.address) {
            window.alert(
                "Merci de renseigner et sauvegarder l'adresse de l'opération et d'accepter les conditions générales avant de confirmer votre panier."
            );
        } else {
            swal({
                title: "Attention",
                text: "Voulez-vous vraiment commander les articles de ce panier ?",
                icon: "warning",
                buttons: ["Annuler", "Commander le panier"],
                dangerMode: false,
            }).then((confirm) => {
                if (confirm) {
                    dispatch(sessionOperations.confirmCart(cart.id));
                }
            });
        }
    };

    /**
     * Permet au super de prendre la main
     */
    const takeCart = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment prendre la main de ce panier ? Le compte à l'origine du panier ne pourra plus y avoir accès."
            )
        ) {
            dispatch(sessionOperations.takeCart(cart.id));
        }
    };

    /**
     * Donne le panier au super
     * Redirige vers la page des paniers ensuite et recharge
     */
    const giveCart = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment envoyer ce panier à l'administrateur ? Vous n'y aurez plus accès ensuite."
            )
        ) {
            dispatch(sessionOperations.giveCart(cart.id)).then((response) => {
                history.push("/compte/paniers");
                window.location.reload();
            });
        }
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                <div
                    id="section-infos-detail-panier"
                    style={{
                        position: "sticky",
                        height: "100px",
                        top: "66px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >

                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="date-detail-panier">
                                    Panier du <b>{moment(cart.date).locale("fr").format("L")}</b>
                                </div>
                                <div>
                                    <b>{cart.countArticles && cart.countArticles}</b> articles |
                                    Montant Total HT :{" "}
                                    <b>{cart.price && centimesToCurrency(cart.price)}</b>
                                </div>
                            </Col>
                            {cart.address && cart.countArticles > 0 && session.user.roles.includes("ROLE_SUPPLIER") ? (
                                <>
                                    <Col md="3">
                                        <h1 className="title-infos-detail-panier">{cart.name}</h1>
                                    </Col>
                                    <Col md="2">
                                        <h1 className="title-infos-detail-panier">Numéro panier {cart.postal}{cart.id}F
                                        </h1>

                                    </Col>

                                </>
                            ) : (
                                <>
                                    <Col md="5">
                                        <h1 className="title-infos-detail-panier">{cart.name}</h1>
                                    </Col>
                                </>
                            )}

                            <Col md="3">
                                {!seeAsSuper && cart.orderLots && cart.orderLots.length > 0 ? (session.user.roles.includes("ROLE_SUPER_CUSTOMER") ? (
                                    <>
                                        {cart.customer.id !== session.user.id ? (
                                            <button className="btn-green" onClick={() => takeCart()}>
                                                Prendre la main sur le panier
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-green"
                                                type="submit"
                                                id="btn-connect-modal"
                                                onClick={() => setSubmitAction('confirm')}
                                            >
                                                Commander le panier
                                            </button>
                                        )}
                                    </>
                                ) : !session.user.roles.includes("ROLE_SUPER_CUSTOMER") && session.user.roles.includes("ROLE_CUSTOMER") ? (
                                    <button className="btn-green"
                                        id="btn-connect-modal" onClick={() => giveCart()}>
                                        Envoyer le panier pour approbation à l’administrateur du compte
                                    </button>
                                ) : session.user.roles.includes("ROLE_SUPPLIER") ? (
                                    <button className="btn-green" type="submit"
                                        id="btn-connect-modal" onClick={(event) => { setSubmitAction('confirm') }}>
                                        Envoyer le panier au client
                                    </button>
                                ) : (
                                    <></>
                                )
                                ) : (
                                    <button className="btn-green">Panier vide</button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row id="navigation-detail-panier" style={{ borderRadius: "6px" }}>
                        <Col md="4">
                            {session?.user?.id &&
                                cart?.customer?.id &&
                                session.user.id === cart.customer.id && (
                                    <button onClick={() => removeCart()}><img height="auto" style={{ width: "15px" }}
                                        src={IconTrash} alt="Icon Trash" />
                                    </button>
                                )}
                        </Col>
                        <Col md="4">
                            {session?.user?.id &&
                                cart?.customer?.id &&
                                cart.id &&
                                session.user.id === cart.customer.id &&
                                (canEditCart || isSupplier) ? (
                                <Link
                                    to="/"
                                    onClick={() =>
                                        dispatch(
                                            sessionOperations.changeActiveCart(
                                                session.user.id,
                                                session.user.roles,
                                                cart.id
                                            )
                                        )
                                    }
                                >
                                    <button>Compléter le panier</button>
                                </Link>
                            ) : (
                                <></>
                            )}
                        </Col>

                        <Col md="4">
                            {cart.orderLots && cart.orderLots.length > 0 && (
                                <button
                                    type="submit"
                                    onClick={() => setSubmitAction('print')}
                                >
                                    Imprimer
                                </button>
                            )}
                        </Col>
                    </Row>
                </Container>
                <Row style={{ marginBottom: "25px" }}>
                    <Col md="12">
                        <Card style={{ padding: "25px" }}>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col md={9}>
                                    <FormGroup>
                                        <Input
                                            name="address"
                                            id="address"
                                            placeholder="Adresse complète d'intervention*"
                                            type="text"
                                            // onChange={formik.handleChange}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setSendCart(true);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address}
                                            disabled={seeAsSuper}
                                        />
                                        {formik.touched.address && formik.errors.address ? (
                                            <div className="input-error">{formik.errors.address}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Input
                                            name="code"
                                            id="code"
                                            placeholder="Code département*"
                                            type="integer"
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setSendCart(true);
                                            }} onBlur={formik.handleBlur}
                                            value={formik.values.code}
                                            disabled
                                        />
                                        {formik.touched.code && formik.errors.code ? (
                                            <div className="input-error">{formik.errors.code}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Input
                                    name="customerReferenceNumber"
                                    id="customerReferenceNumber"
                                    placeholder="N° de référence interne de l'acheteur"
                                    type="text"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setSendCart(true);
                                    }} onBlur={formik.handleBlur}
                                    value={formik.values.customerReferenceNumber}
                                    disabled={seeAsSuper}
                                />
                                {formik.touched.customerReferenceNumber &&
                                    formik.errors.customerReferenceNumber ? (
                                    <div className="input-error">
                                        {formik.errors.customerReferenceNumber}
                                    </div>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    name="moContact"
                                    id="moContact"
                                    placeholder="Coordonnée de la maîtrise d’oeuvre (si existante)"
                                    type="text"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setSendCart(true);
                                    }} onBlur={formik.handleBlur}
                                    value={formik.values.moContact}
                                    disabled={seeAsSuper}
                                />
                                {formik.touched.moContact && formik.errors.moContact ? (
                                    <div className="input-error">
                                        {formik.errors.moContact}
                                    </div>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label style={{ paddingRight: "10px" }}>
                                    Date prévisionnelle de fin d’opération{" "}
                                    <i>(si dérogation aux clauses du contrat)</i>
                                </Label>

                                <DatePicker
                                    dateFormat="d MMMM yyyy"
                                    locale="fr"
                                    selected={
                                        (formik.values &&
                                            formik.values.operationEndingDate !== null &&
                                            new Date(formik.values.operationEndingDate)) ||
                                        null
                                    }
                                    onChange={(val) => {
                                        if (val !== null) {
                                            formik.setFieldValue(
                                                "operationEndingDate",
                                                moment(val).format("YYYY-MM-DD")
                                            );
                                        } else {
                                            formik.setFieldValue("operationEndingDate", null);
                                        }
                                        setSendCart(true);
                                    }}
                                    disabled={seeAsSuper}
                                />
                                {formik.touched.operationEndingDate &&
                                    formik.errors.operationEndingDate ? (
                                    <div className="input-error">
                                        {formik.errors.operationEndingDate}
                                    </div>
                                ) : null}
                            </FormGroup>
                            <Row>
                                <Col md="12">
                                    <div>
                                        <FormGroup>
                                            <Input
                                                name="message"
                                                id="message"
                                                placeholder="*Message à destination du prestataire, exemple : vous êtes informés qu'il convient d'intervenir en site occupé et que vous êtes en possession des relevés topographiques par mail du XX/XX/XXXX"
                                                type="textarea"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    setSendCart(true);
                                                }} onBlur={formik.handleBlur}
                                                value={formik.values.message}
                                                style={{ height: "142px" }}
                                                disabled={seeAsSuper}
                                            />
                                            {formik.touched.message && formik.errors.message ? (
                                                <div className="input-error">{formik.errors.message}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="1">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                id="registerCgu"
                                                type="checkbox"
                                                name="registerCgu"
                                                checked={formik.values.registerCgu}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    setSendCart(true);
                                                }} onBlur={formik.handleBlur}
                                                value={formik.values.registerCgu}
                                            />{" "}
                                            *
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="11">
                                    {" "}
                                    J'ai lu et j'accepte les{" "}
                                    <u>
                                        <Link to="/conditions-generales"> Conditions générales</Link>
                                    </u>
                                    {formik.touched.registerCgu && formik.errors.registerCgu ? (
                                        <div className="input-error">{formik.errors.registerCgu}</div>
                                    ) : null}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </form>

        </>
    );
};

AddressForm.propTypes = {};

AddressForm.defaultProps = {};

export default AddressForm;
