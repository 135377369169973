import React from "react";
import GlobalLoader from "../../../universal/Loader/GlobalLoader/GlobalLoader";
import Paper from "@material-ui/core/Paper";
import {Col, FormGroup, Input, Row} from "reactstrap";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {sessionOperations} from "state/ducks/session";
import swal from "sweetalert";

const RetrieveCart = () => {
    const dispatch = useDispatch();
    const session = useSelector((state) => state.session, shallowEqual);

    let validationSchema = {
        cart_id: Yup.string().required("Ce champ est requis")
    };

    const formik = useFormik({
        initialValues: {
            cart_id: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object(validationSchema),
        onSubmit: (values) => {
            let lastChar = values.cart_id.slice(-1).toUpperCase();
            if (lastChar === 'F') {
                values.cart_type = 'formalized';
            } else if (lastChar === 'L') {
                values.cart_type = 'free';
            } else {
                swal({
                    title: "Erreur",
                    text: "Le dernier caractère du numéro de panier doit être un F ou un L",
                    icon: "error",
                    button: "OK",
                });
                return;
            }

            dispatch(sessionOperations.retrieveCart(values.cart_id, values.cart_type, session.user.id, session.user.email)
            ).then((response) => {
                swal({
                    title: "Le panier a bien été récupéré",
                    icon: "success",
                    button: "OK",
                }).then(
                    (value) => (window.location.href = "/compte/paniers")
                );
            }).catch((error) => {
                // swal({
                //     title: "Erreur",
                //     text: "Une erreur s'est produite lors de la récupération du panier. Veuillez réessayer.",
                //     icon: "error",
                //     button: "OK",
                // });
            });
        },
    });
    return (

        <>
            {(session.isLoading) && (
                <GlobalLoader message={"Chargement en cours ..."}/>
            )}
            <div style={{padding: "0px"}}>
                <Paper elevation={1} style={{marginBottom: "15px"}}>
                    <div style={{padding: "30px"}}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <h3 style={{display: "block", marginBottom: "30px"}}>
                                    Récupérer un panier d'un fournisseur
                                </h3>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="cart_id"
                                                id="cart_id"
                                                placeholder="Numéro de panier"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.cart_id && formik.errors.cart_id ? (
                                                <div className="input-error">
                                                    {formik.errors.cart_id}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        {/*    <FormGroup>*/}
                                        {/*        <Input*/}
                                        {/*            type="select"*/}
                                        {/*            name="cart_type"*/}
                                        {/*            id="cart_type"*/}
                                        {/*            onChange={formik.handleChange}*/}
                                        {/*            onBlur={formik.handleBlur}*/}
                                        {/*            style={{height: "38px"}}*/}
                                        {/*        >*/}
                                        {/*            <option value={"free"}>panier libre</option>*/}
                                        {/*            <option value={"formalized"}>panier formalisé</option>*/}
                                        {/*        </Input>*/}
                                        {/*        {formik.touched.cart_type && formik.errors.cart_type ? (*/}
                                        {/*            <div className="input-error">*/}
                                        {/*                {formik.errors.cart_type}*/}
                                        {/*            </div>*/}
                                        {/*        ) : null}*/}
                                        {/*    </FormGroup>*/}
                                        <button
                                            style={{padding: "8px 20px"}}
                                            type="submit"
                                            variant="contained"
                                            className="btn-green"
                                        >
                                            Récupérer le panier
                                        </button>
                                    </Col>
                                </Row>
                            </div>

                        </form>
                    </div>
                </Paper>
            </div>

        </>
    );
}

export default RetrieveCart;