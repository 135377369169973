import {
  login,
  logout,
  registerCustomer,
  registerSupplier,
  initializeSession,
  addArticle,
  removeArticle,
  removeLot,
  changeActiveCart,
  confirmCart,
  createCart,
  updateCart,
  removeCart,
  printCart,
  takeCart,
  giveCart,
  updateCustomer,
  resetPassword,
  getConfirmedCarts,
  loadCart,
  addFreeArticle,
  updateFreeArticle,
  removeFreeArticle,
  removeFreeSupplier,
  updateSupplier,
  sendCartToCustomer,
  retrieveCart,
  responseToProposalCart,
  getUserType
} from "./actions";
import { upload } from "../general/operations";

const updateSupplierFiles =
  (supplierId, values) => async (dispatch, getState) => {
    let data = {};
    if (values?.companyInsuranceFileUpload) {
      let companyInsuranceFile = await upload(
        values.companyInsuranceFileUpload,
        dispatch
      );
      data.companyInsuranceFile = companyInsuranceFile;
    }
    if (values?.fiscalRegularityFileUpload) {
      let fiscalRegularityFile = await upload(
        values.fiscalRegularityFileUpload,
        dispatch
      );
      data.fiscalRegularityFile = fiscalRegularityFile;
    }
    if (values?.ribUpload) {
      let rib = await upload(values.ribUpload, dispatch);
      data.rib = rib;
    }

    return dispatch(updateSupplier(supplierId, data));
  };

export {
  login,
  logout,
  registerCustomer,
  registerSupplier,
  initializeSession,
  addArticle,
  removeArticle,
  removeLot,
  changeActiveCart,
  confirmCart,
  createCart,
  updateCart,
  removeCart,
  printCart,
  takeCart,
  giveCart,
  updateCustomer,
  resetPassword,
  getConfirmedCarts,
  loadCart,
  addFreeArticle,
  updateFreeArticle,
  removeFreeArticle,
  removeFreeSupplier,
  updateSupplier,
  updateSupplierFiles,
  sendCartToCustomer,
  retrieveCart,
  responseToProposalCart,
  getUserType
};
