import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Article from "./Article";
import { sessionOperations } from "state/ducks/session";
import { centimesToCurrency } from "utils/Utils";

const Lots = (props) => {
  const { lots, isAuthenticated, cart, showDetail } = props;

  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const [detailToggleArticleId, setDetailToggleArticleId] = useState(null);
  const [removeArticles, setRemoveArticles] = useState([]);

  const isProposal = cart?.supplier ? true : false;

  const findLot = (orderLot) => {
    return lots.find(function (lot) {
      return orderLot.lotCode.toString() === lot.code.toString();
    });
  };

  const findArticle = (orderLot, orderArticle) => {
    let theLot = lots.find(function (lot) {
      return orderLot.lotCode.toString() === lot.code.toString();
    });

    if (theLot && theLot.articles.length > 0) {
      return theLot.articles.find(function (article) {
        return orderArticle.articleCode.toString() === article.code.toString();
      });
    }
    return undefined;
  };

  const removeLot = (orderLotId) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce lot du panier ?")) {
      dispatch(sessionOperations.removeLot(orderLotId));
    }
  };

  const removeArticle = (orderLot, orderArticle) => {
    dispatch(sessionOperations.removeArticle(orderArticle.id)).then(
      (response) => {
        setRemoveArticles([
          ...removeArticles,
          { lotId: orderLot.id, articleId: orderArticle.id },
        ]);
      }
    );
  };

  /**
   * Avec le state removeArticle, permet de savoir si un article est toujours dans le panier ou pas suite a une suppression d'article
   */
  const isRemovedArticle = (orderLot, orderArticle) => {
    let removed = removeArticles.find(function (removeArticle) {
      return (
        removeArticle.lotId === orderLot.id &&
        removeArticle.articleId === orderArticle.id
      );
    });
    return removed === undefined ? false : true;
  };

  // Si c'est un fournisseur retourne le rang pour lequel il est classé sur cet article
  const getRank = (lot) => {
    let rank = 1;
    let supplierInfoOnThisLot;
    if (session?.user?.lotGeos) {
      supplierInfoOnThisLot = session.user?.lotGeos.find(
        (lotGeo) =>
          parseInt(lotGeo.lotCode) === parseInt(lot.code) &&
          parseInt(lotGeo.edition) === parseInt(lot.edition) &&
            parseInt(lotGeo.departmentCode) === parseInt(cart?.postal)
      );
      if (supplierInfoOnThisLot) {
        rank = supplierInfoOnThisLot.rank;
      }
    }
    return rank;
  };

  return (
    <div>
      {cart.orderLots.map((orderLot, indexL) => {
        return (
          <div
            style={{ marginBottom: "25px" }}
            className="bloc-lot"
            key={indexL}
          >
            <div className="title-bloc-lot">
              <p className="text-left">
                {orderLot.lotName} ({orderLot.orderArticles.length}{" "}
                {orderLot.orderArticles.length === 1 ? "article" : "articles"})
                - Montant Total HT : {centimesToCurrency(orderLot.price)}
              </p>
              {session?.user?.id &&
                cart?.customer?.id &&
                session.user.id === cart.customer.id &&
                !isProposal && (
                  <button
                    className="btn-white"
                    onClick={() => removeLot(orderLot.id)}
                  >
                    Retirer le lot du panier
                  </button>
                )}
            </div>
            {orderLot.orderArticles.map((orderArticle, indexA) => {
              return (
                <div key={"ld-" + orderLot.id + "-article-" + orderArticle.id}>
                  {findLot(orderLot) &&
                    findArticle(orderLot, orderArticle) &&
                    isRemovedArticle(orderLot, orderArticle) !== true && (
                      <Article
                        departmentCode={cart.department.code}
                        orderLot={orderLot}
                        orderArticle={orderArticle}
                        isAuthenticated={isAuthenticated}
                        article={findArticle(orderLot, orderArticle)}
                        lot={findLot(orderLot)}
                        setDetailToggleArticleId={setDetailToggleArticleId}
                        detailToggleArticleId={detailToggleArticleId}
                        removeArticle={removeArticle}
                        customerId={cart?.customer?.id}
                        showDetail={showDetail}
                        isProposal={isProposal}
                        rank={getRank(findLot(orderLot))}
                      />
                    )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

Lots.propTypes = {};

Lots.defaultProps = {};

export default Lots;
