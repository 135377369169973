export const LOGIN = "session/LOGIN";
export const LOGIN_COMPLETED = "session/LOGIN_COMPLETED";
export const LOGIN_FAILED = "session/LOGIN_FAILED";

export const GET_USER_TYPE = "session/GET_USER_TYPE";

export const LOGOUT = "session/LOGOUT";
export const REGISTER_CUSTOMER = "session/REGISTER_CUSTOMER";
export const REGISTER_CUSTOMER_COMPLETED = "session/REGISTER_CUSTOMER_COMPLETED";
export const REGISTER_CUSTOMER_FAILED = "session/REGISTER_CUSTOMER_FAILED";
export const REGISTER_SUPPLIER = "session/REGISTER_SUPPLIER";
export const REGISTER_SUPPLIER_COMPLETED = "session/REGISTER_SUPPLIER_COMPLETED";
export const REGISTER_SUPPLIER_FAILED = "session/REGISTER_SUPPLIER_FAILED";
export const INITIALIZE_SESSION = "session/INITIALIZE_SESSION";
export const INITIALIZE_SESSION_COMPLETED = "session/INITIALIZE_SESSION_COMPLETED";
export const INITIALIZE_SESSION_FAILED = "session/INITIALIZE_SESSION_FAILED";
export const ADD_ARTICLE = "session/ADD_ARTICLE";
export const ADD_ARTICLE_COMPLETED = "session/ADD_ARTICLE_COMPLETED";
export const ADD_ARTICLE_FAILED = "session/ADD_ARTICLE_FAILED";
export const REMOVE_ARTICLE = "session/REMOVE_ARTICLE";
export const REMOVE_ARTICLE_COMPLETED = "session/REMOVE_ARTICLE_COMPLETED";
export const REMOVE_ARTICLE_FAILED = "session/REMOVE_ARTICLE_FAILED";
export const REMOVE_LOT = "session/REMOVE_LOT";
export const REMOVE_LOT_COMPLETED = "session/REMOVE_LOT_COMPLETED";
export const REMOVE_LOT_FAILED = "session/REMOVE_LOT_FAILED";
export const CHANGE_ACTIVE_CART = "session/CHANGE_ACTIVE_CART";
export const CHANGE_ACTIVE_CART_COMPLETED = "session/CHANGE_ACTIVE_CART_COMPLETED";
export const CHANGE_ACTIVE_CART_FAILED = "session/CHANGE_ACTIVE_CART_FAILED";
export const CONFIRM_CART = "session/CONFIRM_CART";
export const CONFIRM_CART_COMPLETED = "session/CONFIRM_CART_COMPLETED";
export const CONFIRM_CART_FAILED = "session/CONFIRM_CART_FAILED";
export const CREATE_CART = "session/CREATE_CART";
export const CREATE_CART_COMPLETED = "session/CREATE_CART_COMPLETED";
export const CREATE_CART_FAILED = "session/CREATE_CART_FAILED";
export const UPDATE_CART = "session/UPDATE_CART";
export const UPDATE_CART_COMPLETED = "session/UPDATE_CART_COMPLETED";
export const UPDATE_CART_FAILED = "session/UPDATE_CART_FAILED";
export const REMOVE_CART = "session/REMOVE_CART";
export const REMOVE_CART_COMPLETED = "session/REMOVE_CART_COMPLETED";
export const REMOVE_CART_FAILED = "session/REMOVE_CART_FAILED";
export const PRINT_CART = "session/PRINT_CART";
export const PRINT_CART_COMPLETED = "session/PRINT_CART_COMPLETED";
export const PRINT_CART_FAILED = "session/PRINT_CART_FAILED";

export const TAKE_CART = "session/TAKE_CART";
export const TAKE_CART_COMPLETED = "session/TAKE_CART_COMPLETED";
export const TAKE_CART_FAILED = "session/TAKE_CART_FAILED";

export const GIVE_CART = "session/GIVE_CART";
export const GIVE_CART_COMPLETED = "session/GIVE_CART_COMPLETED";
export const GIVE_CART_FAILED = "session/GIVE_CART_FAILED";

export const UPDATE_CUSTOMER = "session/UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_COMPLETED = "session/UPDATE_CUSTOMER_COMPLETED";
export const UPDATE_CUSTOMER_FAILED = "session/UPDATE_CUSTOMER_FAILED";

export const RESET_PASSWORD = "session/RESET_PASSWORD";
export const RESET_PASSWORD_COMPLETED = "session/RESET_PASSWORD_COMPLETED";
export const RESET_PASSWORD_FAILED = "session/RESET_PASSWORD_FAILED";

export const GET_CONFIRMED_CARTS = "session/GET_CONFIRMED_CARTS";
export const GET_CONFIRMED_CARTS_COMPLETED = "session/GET_CONFIRMED_CARTS_COMPLETED";
export const GET_CONFIRMED_CARTS_FAILED = "session/GET_CONFIRMED_CARTS_FAILED";

export const LOAD_CART = "session/LOAD_CART";
export const LOAD_CART_COMPLETED = "session/LOAD_CART_COMPLETED";
export const LOAD_CART_FAILED = "session/LOAD_CART_FAILED";

export const UPDATE_SUPPLIER = "session/UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_COMPLETED = "session/UPDATE_SUPPLIER_COMPLETED";
export const UPDATE_SUPPLIER_FAILED = "session/UPDATE_SUPPLIER_FAILED";

export const ADD_FREE_ARTICLE = "session/ADD_FREE_ARTICLE";
export const ADD_FREE_ARTICLE_COMPLETED = "session/ADD_FREE_ARTICLE_COMPLETED";
export const ADD_FREE_ARTICLE_FAILED = "session/ADD_FREE_ARTICLE_FAILED";

export const UPDATE_FREE_ARTICLE = "session/UPDATE_FREE_ARTICLE";
export const UPDATE_FREE_ARTICLE_COMPLETED = "session/UPDATE_FREE_ARTICLE_COMPLETED";
export const UPDATE_FREE_ARTICLE_FAILED = "session/UPDATE_FREE_ARTICLE_FAILED";

export const REMOVE_FREE_ARTICLE = "session/REMOVE_FREE_ARTICLE";
export const REMOVE_FREE_ARTICLE_COMPLETED = "session/REMOVE_FREE_ARTICLE_COMPLETED";
export const REMOVE_FREE_ARTICLE_FAILED = "session/REMOVE_FREE_ARTICLE_FAILED";

export const REMOVE_FREE_SUPPLIER = "session/REMOVE_FREE_SUPPLIER";
export const REMOVE_FREE_SUPPLIER_COMPLETED = "session/REMOVE_FREE_SUPPLIER_COMPLETED";
export const REMOVE_FREE_SUPPLIER_FAILED = "session/REMOVE_FREE_SUPPLIER_FAILED";

export const SEND_CART_TO_CUSTOMER = "session/SEND_CART_TO_CUSTOMER";

export const RETRIEVE_CART = "session/RETRIEVE_CART";
export const SEND_CART_TO_CUSTOMER_COMPLETED = "session/SEND_CART_TO_CUSTOMER_COMPLETED";
export const SEND_CART_TO_CUSTOMER_FAILED = "session/SEND_CART_TO_CUSTOMER_FAILED";

export const RESPONSE_PROPOSAL_CART = "session/RESPONSE_PROPOSAL_CART";
export const RESPONSE_PROPOSAL_CART_COMPLETED = "session/RESPONSE_PROPOSAL_CART_COMPLETED";
export const RESPONSE_PROPOSAL_CART_FAILED = "session/RESPONSE_PROPOSAL_CART_FAILED";
