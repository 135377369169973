import React from "react";
// import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import ModalForm from "../Modals/ModalForm";
// import { operations } from "state/ducks/supersupplier";

const DataTable = (props) => {
  const { admin, items, buttonLabel } = props;

  // const dispatch = useDispatch();

  // const deleteItem = (itemId) => {
  //   if (
  //     window.confirm(
  //       "Voulez-vous supprimer ce compte ? Il sera supprimer de la base de données Centralis, tous ses paniers en cours vous seront attribués ?"
  //     )
  //   ) {
  //     dispatch(operations.removeSupplierAsSuper(itemId)).then((response) => {
  //       // Recharge la page pour actualiser les paniers en cours que le super doit récupérer après une suppression
  //       window.location.reload();
  //     });
  //   }
  // };

  const itemsRender = items.map((item) => {
    return (
      <tr key={item.id}>
        <th scope="row">
          {item.lastname} {item.firstname}
        </th>
        <td>{item.email}</td>
        <td>{item.codeId}</td>
        {/* <td>{item.active ? "Activé" : "Désactivé"}</td> */}
        <td>
          <ModalForm item={item} admin={admin} buttonLabel={buttonLabel} />
        </td>
        <td>
          <Link
            to={{
              pathname: `/compte/fournisseur/comptes/${item.id}/commandes`,
            }}
          >
            <Button
              className="btn-green float-right"
              style={{ fontSize: "75%" }}
            >
              Paniers en cours
            </Button>
          </Link>
        </td>
        <td>
          {item.departmentCodes && item.departmentCodes.length > 0 && item.departmentCodes.map((departmentCode) => {
            return (
              <span key={departmentCode}>
                {departmentCode}, 
              </span>
            );
          })
          }
        </td>
        {/* <td>
          <Button
            color="danger"
            style={{ fontSize: "75%" }}
            onClick={() => deleteItem(item.id)}
          >
            Supprimer
          </Button>
        </td> */}
      </tr>
    );
  });

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Email</th>
          <th>ID</th>
          <th></th>
          <th></th>
          <th>Départements</th>
        </tr>
      </thead>
      <tbody>{itemsRender}</tbody>
    </Table>
  );
};

export default DataTable;
