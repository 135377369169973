import React from "react";
import { Stepper, Step, StepLabel, StepContent, Paper, CircularProgress, Button } from '@material-ui/core';
import { Row, Col } from "react-bootstrap";
import Layout from "./Common/Layout"
import FormSupplierLoad from "./FormSupplierLoad"
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const FormSupplier = (props) => {

  const {
    activeStep,
    steps,
    getStepFunction,
    handleBack,
    isNextDisable,
    isLoading,
    handleReset,
    getStepContent,
    initialValues,
    forThePhase,
    closeCandidate,
    phaseCandidate,
    viewMode
  } = props; 

  const outerTheme = createTheme({
    palette: {
      primary: {
        main: "#db7124",
      },
      secondary: {
        main: "#abd156",
      },
    },
  });

  return (
    <Layout>
    {closeCandidate && viewMode !== true
    ?
    <div className="mt-3">Les candidatures sont actuellement closes.</div>
    :
    forThePhase !== phaseCandidate && viewMode !== true
    ?
    <div className="mt-3">Le délai pour déposer sa candidature est expiré. Merci de votre participation.</div>
    :
      <>
      <FormSupplierLoad initialValues={initialValues} open={forThePhase === phaseCandidate} viewMode={viewMode}/>
      {initialValues.id !== null &&
        <>
          <h4 className="text-center">Compléter votre candidature ci-dessous</h4>
          <p className="text-center">Code de suivi chargé : {initialValues.token}</p>
        {(initialValues.phase === phaseCandidate || viewMode === true) ?
        <ThemeProvider theme={outerTheme}>
          <div className="mt-3">
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {getStepContent(index)}
                    {viewMode !== true && <Row>
                      <Col xs={12}>
                        {(!initialValues.step12.closeAndGenerate && initialValues.accepted !== true) &&
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Retour
                          </Button>
                        }
                        {activeStep < (steps.length - 1) &&
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isNextDisable(index) || isLoading}
                            onClick={(e) => getStepFunction(index)}
                          >
                            {isLoading
                              ? <CircularProgress color="white" size={18} />
                              : 'Suivant'
                            }
                          </Button>
                         }
                      </Col>
                    </Row>}
                  </StepContent>
                </Step>
              ))}
              {activeStep === steps.length && (
                <Paper square elevation={0}>
                  <Row className="mt-3">
                    <Col xs={4}>
                      <div className="mt-3">
                        <Button onClick={handleReset} variant="outlined" color="primary">
                          Valider
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Paper>
              )}
            </Stepper>
          </div>
        </ThemeProvider>
        :
          <div className="mt-12">
            Votre candidature ne correspond pas à la phase actuelle
          </div>
        }
        </>
        }
      </>
    }
    </Layout>
  );

};

FormSupplier.propTypes = {

};

FormSupplier.defaultProps = {

};

export default FormSupplier;
