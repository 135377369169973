import React from "react";
import { useDispatch } from "react-redux";
import Step9 from "./Step9"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";
import {SUPPORTED_FORMATS_DOCUMENTS, SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, FILE_SIZE }from "constants/constants";

// Mémoire technique
const Step9Container = (props) => {

  const {
    formRef,
    initialValues,
    contractors
  } = props

  const dispatch = useDispatch();
  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    presentationHuman: Yup.string().nullable().max(3000, '3000 caractères maximum').required('Ce champ est requis'),
    presentationOperative: Yup.string().nullable().max(2000, '2000 caractères maximum').required('Ce champ est requis'),
    presentationEnvironment: Yup.string().nullable().max(2000, '2000 caractères maximum').required('Ce champ est requis'),
    authorizationSEPA: Yup.string().default('yes'),
    proofs: Yup.array().of(
      Yup.object().shape({
        proof: Yup.string().nullable(),
        proofId: Yup.string().nullable(),
        proofUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('proof', {
            is: null,
            then: Yup.mixed().nullable().required('Ce champ est requis'),
        }),
      })
    ),
  })

  return (
    <>
    <Formik
       initialValues={initialValues.step9}
       validationSchema={validationSchema}
       onSubmit={values => {dispatch(
        formSupplierOperations.setStep9(values, initialValues.id))}}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step9 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues} contractors={contractors}/>
         </>
       )}
     </Formik>
     </>
  );

};

Step9Container.propTypes = {
};

Step9Container.defaultProps = {

};

export default Step9Container;
