import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Account from "./../Account";
import Paper from "@material-ui/core/Paper";
import { Container, FormGroup, Row, Col, Input, Label } from "reactstrap";
import Select from "react-select";
import DataTable from "../../SupplierAccount/Accounts/components/Tables/DataTable";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sessionOperations } from "state/ducks/session";
import makeAnimated from "react-select/animated";
import { operations as supersupplierOperations } from "state/ducks/supersupplier";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";


const MyInfos = (props) => {
    const dispatch = useDispatch();

    const session = useSelector((state) => state.session, shallowEqual);

    const departments = useSelector(
        (state) => state.store.departments,
        shallowEqual
    );

    const categories = useSelector(
        (state) => state.store.categories,
        shallowEqual
    );

    const supersupplier = useSelector(
        (state) => state.supersupplier,
        shallowEqual
    );

    const [accounts, setAccounts] = useState(null);

    const [departementsChoosen, setDepartementsChoosen] = useState([]);
    const [categoryChoosen, setCategoryChoosen] = useState([]);
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (
            session?.user?.departments &&
            session.user.departments &&
            init === false
        ) {
            let dptOptions = [];
            for (var i = 0; i < session.user.departments.length; i++) {
                dptOptions[i] = {
                    value: `/api/departments/${session.user.departments[i].id}`,
                    label: `${session.user.departments[i].name} - ${session.user.departments[i].code}`,
                };
            }
            let dptCategoryOption = null;
            if (session?.user?.category) {
                dptCategoryOption = {
                    value: `/api/categories/${session.user.category.id}`,
                    label: `${session.user.category.name}`,
                };
            }
            setDepartementsChoosen(dptOptions);
            setCategoryChoosen(dptCategoryOption);
            setInit(true);
        }
        if (
            session.isLoading === false &&
            session.init === true &&
            supersupplier.isLoading === false &&
            supersupplier.init === false &&
            accounts === null
        ) {
            dispatch(
                supersupplierOperations.getSupplierAccounts(session.user.id)
            ).then((response) => {
                setAccounts(response.data);
            });
        }
    }, [dispatch, departementsChoosen, init, session]);

    const animatedComponents = makeAnimated();

    let departmentOptions = [];
    if (departments.data) {
        for (var i = 0; i < departments.data.length; i++) {
            departmentOptions[i] = {
                value: `/api/departments/${departments.data[i].id}`,
                label: `${departments.data[i].name} - ${departments.data[i].code}`,
            };
        }
    }
    const departmentsOnChange = (values) => {
        let dpts = [];
        for (var i = 0; i < values.length; i++) {
            dpts.push(values[i]);
        }
        setDepartementsChoosen(dpts);
    };

    let categoryOptions = [];
    if (categories.data) {
        for (var ii = 0; ii < categories.data.length; ii++) {
            categoryOptions[ii] = {
                value: `/api/categories/${categories.data[ii].id}`,
                label: `${categories.data[ii].name}`,
            };
        }
    }
    const categoryOnChange = (value) => {
        setCategoryChoosen(value);
    };

    let initialValues = {
        name: session.user !== null ? session.user.name : "",
        firstname: session.user !== null ? session.user.firstname : "",
        lastname: session.user !== null ? session.user.lastname : "",
        // email: session.user !== null ? session.user.email: '',
        phone: session.user !== null ? session.user.phone : "",
        address: {
            id:
                session.user !== null
                    ? `/api/addresses/${session.user.address.id}`
                    : "",
            street: session.user !== null ? session.user.address.street : "",
            postal: session.user !== null ? session.user.address.postal : "",
            city: session.user !== null ? session.user.address.city : "",
        },
        remunerationEmail:
            session.user !== null ? session.user.remunerationEmail : "",
        remunerationPhone:
            session.user !== null ? session.user.remunerationPhone : "",
        remunerationName:
            session.user !== null ? session.user.remunerationName : "",
        remunerationFunction:
            session.user !== null ? session.user.remunerationFunction : "",
        departementsChoosen: departementsChoosen,
        categoryChoosen: categoryChoosen,
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            // email: Yup.string().email('Merci de renseigner votre mail').required('Ce champ est requis'),
            name: Yup.string().required("Ce champ est requis"),
            firstname: Yup.string().required("Ce champ est requis"),
            lastname: Yup.string().required("Ce champ est requis"),
            phone: Yup.string().required("Ce champ est requis"),
            address: Yup.object({
                city: Yup.string().required("Ce champ est requis"),
                postal: Yup.string().required("Ce champ est requis"),
                street: Yup.string().required("Ce champ est requis"),
            }),
            remunerationEmail: Yup.string().email("Merci de renseigner un mail valide").nullable(),
            remunerationPhone: Yup.string().required("Ce champ est requis").nullable(),
            remunerationName: Yup.string().required("Ce champ est requis").nullable(),
            remunerationFunction: Yup.string().required("Ce champ est requis").nullable(),
        }),
        onSubmit: (values, { setFieldError }) => {
            if (!departementsChoosen.length) {
                setFieldError('departementsChoosen', 'Veuillez sélectionner au moins un département');
                return;
            }
            if (!categoryChoosen) {
                setFieldError('categoryChoosen', 'Veuillez sélectionner une catégorie');
                return;
            }
            let departmentsURI = [];
            for (var i = 0; i < departementsChoosen.length; i++) {
                departmentsURI[i] = departementsChoosen[i].value;
            }
            let categoryURI = null;
            if (categoryChoosen?.value) {
                categoryURI = categoryChoosen.value;
            }
            dispatch(
                sessionOperations.updateSupplier(session.user.id, {
                    ...values,
                    departments: departmentsURI,
                    category: categoryURI,
                })
            );
        },
    });

    return (
        <Account>
            <div style={{ padding: "0px" }}>
                <Paper elevation={1} style={{ marginBottom: "15px" }}>
                    <div style={{ padding: "30px" }}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                {session?.user?.name && (
                                    <h2 style={{ width: "50%", float: "left" }}>
                                        {session.user.name}
                                    </h2>
                                )}
                                <a
                                    style={{
                                        color: "#DB7124",
                                        textDecoration: "underline",
                                        float: "right",
                                        width: "50%",
                                        textAlign: "right",
                                    }}
                                    href="mailto:contact@centralis.site?subject=Supression de compte"
                                >
                                    Demande de suppression du compte
                                </a>

                                <Label style={{ display: "block", marginTop: "20px" }}>
                                    (Compte{" "}
                                    {session?.user?.type && session.user.type === "free"
                                        ? "libre"
                                        : "formalisé"}
                                    )
                                </Label>

                                <Row>
                                    <Col md="12">
                                        {session?.user?.codeId && `Mon code de connexion: ${session.user.codeId}`}
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="lastname"
                                                id="lastname"
                                                placeholder="Nom*"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lastname}
                                            />
                                            {formik.touched.lastname && formik.errors.lastname ? (
                                                <div className="input-error">
                                                    {formik.errors.lastname}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="firstname"
                                                id="firstname"
                                                placeholder="Prénom*"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.firstname}
                                            />
                                            {formik.touched.firstname && formik.errors.firstname ? (
                                                <div className="input-error">
                                                    {formik.errors.firstname}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Label style={{ display: "block", marginTop: "20px" }}>
                                    Adresse*
                                </Label>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Input
                                                name="address.street"
                                                id="street"
                                                placeholder="N et Rue"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address.street}
                                            />
                                            {formik.touched.address &&
                                                formik.touched.address.street &&
                                                formik.errors.address &&
                                                formik.errors.address.street ? (
                                                <div className="input-error">
                                                    {formik.errors.address.street}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="address.postal"
                                                id="postal"
                                                placeholder="Code postal*"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address.postal}
                                            />
                                            {formik.touched.address &&
                                                formik.touched.address.postal &&
                                                formik.errors.address &&
                                                formik.errors.address.postal ? (
                                                <div className="input-error">
                                                    {formik.errors.address.postal}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="address.city"
                                                id="city"
                                                placeholder="Ville*"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address.city}
                                            />
                                            {formik.touched.address &&
                                                formik.touched.address.city &&
                                                formik.errors.address &&
                                                formik.errors.address.city ? (
                                                <div className="input-error">
                                                    {formik.errors.address.city}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Label style={{ display: "block", marginTop: "20px" }}>
                                    Contact*
                                </Label>
                                <Row>
                                    <Col md="6">{session.user.email}</Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="phone"
                                                id="phone"
                                                placeholder="Téléphone*"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                            />
                                            {formik.touched.phone && formik.errors.phone ? (
                                                <div className="input-error">{formik.errors.phone}</div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <Label>
                                            <b>Interlocuteur pour la facturation</b>
                                        </Label>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="remunerationEmail"
                                                id="remunerationEmail"
                                                placeholder="Email"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.remunerationEmail}
                                            />
                                            {formik.touched.remunerationEmail &&
                                                formik.errors.remunerationEmail ? (
                                                <div className="input-error">
                                                    {formik.errors.remunerationEmail}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="remunerationPhone"
                                                id="remunerationPhone"
                                                placeholder="Tel"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.remunerationPhone}
                                            />
                                            {formik.touched.remunerationPhone &&
                                                formik.errors.remunerationPhone ? (
                                                <div className="input-error">
                                                    {formik.errors.remunerationPhone}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="remunerationName"
                                                id="remunerationName"
                                                placeholder="Nom"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.remunerationName}
                                            />
                                            {formik.touched.remunerationName &&
                                                formik.errors.remunerationName ? (
                                                <div className="input-error">
                                                    {formik.errors.remunerationName}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Input
                                                name="remunerationFunction"
                                                id="remunerationFunction"
                                                placeholder="Fonction"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.remunerationFunction}
                                            />
                                            {formik.touched.firstname &&
                                                formik.errors.remunerationFunction ? (
                                                <div className="input-error">
                                                    {formik.errors.remunerationFunction}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12">
                                        {categories.isLoading ? (
                                            <Label for="departements">
                                                Chargement des départements...
                                            </Label>
                                        ) : (
                                            <>
                                                <FormGroup>
                                                    <Label for="departements">
                                                        Départements par défaut pour le catalogue libre
                                                    </Label>
                                                    <Select
                                                        id="departements"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={departementsChoosen}
                                                        value={departementsChoosen}
                                                        isMulti
                                                        options={departmentOptions}
                                                        onChange={departmentsOnChange}
                                                    />
                                                </FormGroup>
                                                {formik.touched.departementsChoosen && formik.errors.departementsChoosen ? (
                                                    <div
                                                        className="input-error">{formik.errors.departementsChoosen}</div>
                                                ) : null}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        {categories.isLoading ? (
                                            <Label for="categories">
                                                Chargement des catégories...
                                            </Label>
                                        ) : (
                                            <>
                                                <FormGroup>
                                                    <Label for="categories">
                                                        Catégorie par défaut pour le catalogue libre
                                                    </Label>
                                                    <Select
                                                        id="categories"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={categoryChoosen}
                                                        value={categoryChoosen}
                                                        options={categoryOptions}
                                                        onChange={categoryOnChange}
                                                    />
                                                </FormGroup>
                                                {formik.touched.categoryChoosen && formik.errors.categoryChoosen ? (
                                                    <div className="input-error">{formik.errors.categoryChoosen}</div>
                                                ) : null}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            {!categories.isLoading && !departments.isLoading && (
                                <button
                                    style={{ marginTop: "30px", marginLeft: "70%", width: "30%" }}
                                    type="submit"
                                    variant="contained"
                                    className="btn-green"
                                >
                                    Mettre à jour le profil
                                </button>
                            )}
                        </form>
                    </div>
                </Paper>
            </div>
            {session.user.roles.includes("ROLE_SUPER_SUPPLIER") &&
                session.user.type === "formalized" && (
                    <div>
                        {(session.isLoading || supersupplier.isLoading) && (
                            <GlobalLoader message={"Chargement en cours ..."} />
                        )}
                        <div style={{ padding: "0px" }}>
                            <Paper elevation={1} style={{ marginBottom: "15px" }}>
                                <div style={{ padding: "30px" }}>
                                    <Container className="App">
                                        {supersupplier.supplierAccounts.length > 0 && (
                                            <Row>
                                                <Col>
                                                    <DataTable
                                                        items={supersupplier.supplierAccounts}
                                                        admin={session.user}
                                                        buttonLabel="Editer"
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Container>
                                </div>
                            </Paper>
                        </div>
                    </div>
                )}
        </Account>
    );
};

MyInfos.propTypes = {};

MyInfos.defaultProps = {};

export default MyInfos;
