import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import "./Filters.css";
import { useDispatch } from "react-redux";
import { storeOperations } from "state/ducks/store";
import iconSearch from 'assets/img/icon_search.svg';
import { useHistory } from "react-router-dom";

const Filters = (props) => {

    const dispatch = useDispatch();
    let history = useHistory();

    const {
      lots,
      filters
    } = props

    const [searchPrestation, setSearchPrestation] = useState(filters.prestation);

    return (
      <nav id="Filters">
        <Container>
          <Row>
            <Col lg="4" style={{paddingBottom: '10px'}}>
              <FormGroup className="mb-0">
                <Input
                  type="select"
                  name="selectLot"
                  id="selectLot"
                  value={filters.category}
                  onChange={(e) =>{
                      dispatch(storeOperations.filtersLots(e.target.value, filters.prestation));
                      history.push('/catalogue?lot='+e.target.value);
                    }
                  }
                >

                  <option value="all">Toutes les catégories</option>
                  {
                    lots.map((lot) => {
                      return(
                        <option value={lot.code} key={'filter-category-'+lot.code}>
                          {lot.name}
                        </option>
                      )
                    })
                  }

                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-0">
                <Input
                  type="text"
                  name="text"
                  id="prestation"
                  placeholder="Saisir une prestation, ex : Diagnostic"
                  value={searchPrestation}
                  onChange={(e) => setSearchPrestation(e.target.value)}
                />
                <button className="icon-search" onClick={() => dispatch(storeOperations.filtersLots(filters.category, searchPrestation, filters.edition))} >
                    <img src={iconSearch} alt="icon search filter"/>
                </button>
              </FormGroup>
            </Col>
            <Col lg="2">
              <button
                onClick={(e) => dispatch(storeOperations.filtersLots('all', ''), setSearchPrestation(''))}
                className="btn-green"
              >
                Réinitialiser
              </button>
            </Col>
            {process?.env?.NODE_ENV && process.env.NODE_ENV === "development" &&
              <Col lg="12">
                <FormGroup className="mb-0">
                  <Input
                    type="text"
                    name="edition"
                    id="edition"
                    placeholder="Saisir une edition (DEV)"
                    value={filters.edition ? filters.edition : ''}
                    onChange={(e) =>{
                        dispatch(storeOperations.filtersLots(filters.category, filters.prestation, e.target.value));
                      }
                    }
                  />
                </FormGroup>
              </Col>
            }
          </Row>
        </Container>
      </nav>
    );

}

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;
