import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import Banner from "views/universal/Banner/Banner";
import Filters from "./Filters/Filters";
import Categories from "./Categories/Categories";
import Rgpd from "views/universal/Rgpd/Rgpd";
import queryString from "query-string";
import { storeOperations } from "state/ducks/store";
import { sessionSelectors } from "state/ducks/session";

const FreeCatalog = (props) => {
  const dispatch = useDispatch();

  const paramUrl = queryString.parse(props.location.search);

  // Recupère le selector du panier libre
  const cart = useSelector((state) => {
    return sessionSelectors.activeFreeCartSelector(state);
  });

  const categories = useSelector(
    (state) => state.store.categories,
    shallowEqual
  );

  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const freeArticlesBySupplier = useSelector(
    (state) => state.store.freeArticlesBySupplier,
    shallowEqual
  );

  const session = useSelector((state) => state.session, shallowEqual);

  let categoryId = paramUrl.category;
  let departmentId = paramUrl.department;
  let category;

  if (categoryId === undefined) {
    categoryId = "all";
  } else {
    category = categories.data.find((value) => {
      return parseInt(value.id) === parseInt(categoryId);
    });
  }

  if (cart?.department?.id) {
    departmentId = cart.department.id;
  }

  if (departmentId === undefined) {
    departmentId = "74"; // Paris
  }

  useEffect(() => {
    if (freeArticlesBySupplier.filters.category !== categoryId) {
      dispatch(
        storeOperations.filtersFreeArticlesBySupplier(
          categoryId,
          freeArticlesBySupplier.filters.department
        )
      );
    }
    if (freeArticlesBySupplier.filters.department !== departmentId) {
      dispatch(
        storeOperations.filtersFreeArticlesBySupplier(
          freeArticlesBySupplier.filters.category,
          departmentId
        )
      );
    }
  }, [dispatch, freeArticlesBySupplier.filters, departmentId, categoryId]);

  const descriptionBanner =
    "Attention, dans cet espace, il appartient à chaque acheteur de vérifier le profil des prestataires et de respecter les seuils ainsi que les règles de passation imposées par le Code de la commande publique. Les entreprises n'ont pas été mises en concurrence et se sont inscrites librement et directement sans analyses ni interventions de la centrale d’achat.";

  return (
    <Layout>
      {categories.isLoading ||
      categories.session ||
      freeArticlesBySupplier.isLoading ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <>
          {category !== "all" &&
          category !== undefined &&
          category?.image?.contentUrl ? (
            <Banner
              titleBanner={category.name}
              image={encodeURI(category.image.contentUrl)}
            />
          ) : (
            <Banner
              titleBanner="Référencement libre"
              desc={descriptionBanner}
            />
          )}
          <Filters
            filters={freeArticlesBySupplier.filters}
            categories={categories.data}
            departments={departments.data}
            departmentCart={cart?.department}
          />
          <Categories
            categories={categories.data}
            filters={freeArticlesBySupplier.filters}
            cartSelector={cart}
            user={session?.user}
          />
        </>
      )}

      <Rgpd />
    </Layout>
  );
};

FreeCatalog.propTypes = {};

FreeCatalog.defaultProps = {};

export default FreeCatalog;
