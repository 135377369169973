import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import "./Register.css";
import RegisterCustomer from "./RegisterCustomer";
import RegisterSupplier from "./RegisterSupplier";

const Register = (props) => {
  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const [activeTab, setActiveTab] = useState("0");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav tabs style={{ borderBottom: "0px"}}>
        <NavItem className="col" style={{textAlign: 'center', fontSize: "1.5em"}}>
            <NavLink
                style={activeTab === "0" ? {backgroundColor: "#DB7124", color: "#fff"} : activeTab === "1" ? {backgroundColor: "#DB7124", color: "#fff"} : {backgroundColor: "#fff", color: "#000"}}
                className={classnames({
                    active: activeTab === "1",
                })}
                onClick={() => {
                    toggle("1");
                }}
            >
            Je suis un acheteur
          </NavLink>
        </NavItem>
        <NavItem className="col" style={{textAlign: 'center', fontSize: "1.5em"}}>
            <NavLink
                style={activeTab === "0" ? {backgroundColor: "#DB7124", color: "#fff"} : activeTab === "2" ? {backgroundColor: "#DB7124", color: "#fff"} : {backgroundColor: "#fff", color: "#000"}}
                className={classnames({
                    active: activeTab === "2",
                })}
                onClick={() => {
                    toggle("2");
                }}
            >
            Je suis un prestataire
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab && ( // Ajoute une condition pour vérifier si un onglet est actif
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <RegisterCustomer departments={departments} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <RegisterSupplier departments={departments} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
      )}
    </div>
  );
};

export default Register;
