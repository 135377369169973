import React from "react";
import { useDispatch } from "react-redux";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSvg from "assets/img/icon-preloader-connect.svg";
import { sessionOperations } from "state/ducks/session";
import { modalsOperations } from "state/ducks/modal";

const CartForm = (props) => {
  const { departments, session, from, cartType } = props;

  const dispatch = useDispatch();

  const forSupplier = from === "supplier";

  const formik = useFormik({
    initialValues: {
      department: "",
      name: "",
    },
    validationSchema: Yup.object({
      department: Yup.string().required("Ce champ est requis"),
      name: Yup.string().required("Ce champ est requis"),
    }),
    onSubmit: (values) => {
      // Laisse la modle ouverte en cas d'erreur
      dispatch(
        sessionOperations.createCart(values, cartType, forSupplier)
      ).then(
        (result) => {
          dispatch(modalsOperations.getModalStatus(null, false, null));
        },
        (error) => {}
      );
    },
  });

  const isSupplier =
    session?.user?.roles && session.user.roles.includes("ROLE_SUPPLIER")
      ? true
      : false;

  // Permet de savoir si un fournisseur est présent sur un département en fonction de son code
  const hasThisDepartmentCode = (departmentCode) => {
    if (cartType === "free") {
      return session?.user?.freeDepartmentCodes &&
        session.user.freeDepartmentCodes.find((freeDepartmentCode) => {
          return freeDepartmentCode.toString() === departmentCode.toString();
        })
        ? true
        : false;
    } else {
      return session?.user?.lotGeos &&
        session.user.lotGeos.find((lotGeo) => {
          return lotGeo.departmentCode.toString() === departmentCode.toString();
        })
        ? true
        : false;
    }
  };

  return (
    <Row>
      <Col md="12">
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Input
              name="name"
              id="name"
              placeholder="Nom du panier"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="input-error">{formik.errors.name}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Input
              type="select"
              name="department"
              id="department"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue=""
            >
              <option value="" hidden>
                Département de l'opération
              </option>
              {departments.data.map((department, i) => {
                if (!isSupplier || hasThisDepartmentCode(department.code)) {
                  return (
                    <option value={`api/departments/${department.id}`} key={i}>
                      {department.code} - {department.name}
                    </option>
                  );
                }
                return null;
              })}
            </Input>
            {formik.touched.department && formik.errors.department ? (
              <div className="input-error">{formik.errors.department}</div>
            ) : null}
          </FormGroup>
          <div>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="btn-connect-modal"
              type="submit"
              className="btn-green"
            >
              <div style={{ lineHeight: "1" }}>Créer un panier</div>
              <div
                style={
                  session.isLoading
                    ? { marginLeft: "0", opacity: "1" }
                    : { marginLeft: "0", opacity: "0" }
                }
                className="preloader-connect-user"
              >
                <img src={LoadingSvg} alt="Loader" />
              </div>
            </button>
          </div>
        </form>
      </Col>
    </Row>
  );
};

// export
export default CartForm;
