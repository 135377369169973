import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { sessionOperations } from "state/ducks/session";
import IconTrash from "../../../../assets/img/icon-trash-black.svg";

const Actions = (props) => {
  const { cart, seeAsSuper } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const session = useSelector((state) => state.session, shallowEqual);

  const removeCart = () => {
    if (window.confirm("Voulez-vous vraiment supprimer ce panier ?")) {
      dispatch(sessionOperations.removeCart(cart.id, "formalized")).then(
        (success) => {
          if (session.user.roles.includes("ROLE_SUPPLIER")) {
            return history.push("/compte/fournisseur/paniers");
          }
          history.push("/compte/paniers");
          window.location.reload();
          return;
        }
      );
    }
  };

  const isSupplier =
    !!(session?.user?.roles && session.user.roles.includes("ROLE_SUPPLIER"));

  let canEditCart = false;
  if (isSupplier) {
    if (cart.status === 5) {
      canEditCart = true;
    } else {
      canEditCart = false;
    }
  } else {
    canEditCart = !cart.supplier;
  }

  if (seeAsSuper) {
    canEditCart = false;
  }

  return (
    <Container>
      <Row id="navigation-detail-panier" className="mr-0 ml-0" style={{borderRadius: "6px"}}>
        <Col md="4">
          {session?.user?.id &&
            cart?.customer?.id &&
            session.user.id === cart.customer.id && (
              <button onClick={() => removeCart()}> <img height="auto" style={{width: "15px"}} src={IconTrash} alt="Icon Trash"/></button>
            )}
        </Col>
        <Col md="4">
          {session?.user?.id &&
          cart?.customer?.id &&
          cart.id &&
          session.user.id === cart.customer.id &&
          (canEditCart || isSupplier) ? (
            <Link
              to="/"
              onClick={() =>
                dispatch(
                  sessionOperations.changeActiveCart(
                    session.user.id,
                    session.user.roles,
                    cart.id
                  )
                )
              }
            >
              <button>Compléter le panier</button>
            </Link>
          ) : (
            <></>
          )}
        </Col>
        <Col md="4">
          {cart.orderLots && cart.orderLots.length > 0 && (
            <button
              onClick={() => dispatch(sessionOperations.printCart(cart.id))}
            >
              Imprimer
            </button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Actions.propTypes = {};

Actions.defaultProps = {};

export default Actions;
