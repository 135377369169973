import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col, Collapse, Button, Input } from "reactstrap";
import { modalsOperations } from "state/ducks/modal";
import ArticleVariations from "./ArticleVariations";
import { centimesToCurrency } from "utils/Utils";

const Article = (props) => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const {
    article,
    lot,
    departmentCode,
    orderLot,
    orderArticle,
    isAuthenticated,
    setDetailToggleArticleId,
    detailToggleArticleId,
    removeArticle,
    customerId,
    showDetail,
    isProposal,
    rank,
  } = props;

  // Toggle
  const [detailToggle, setDetailToggle] = useState(false);
  const updateToggle = (toggle, detailToggleArticleId) => {
    if (toggle) {
      setDetailToggleArticleId(detailToggleArticleId);
    } else {
      setDetailToggleArticleId(null);
    }
  };
  useEffect(() => {
    setDetailToggle(detailToggleArticleId === article.id);
  }, [detailToggleArticleId, article]);

  let pricesFilter = article.prices;
  if (rank !== undefined) {
    pricesFilter = pricesFilter.filter(function (p) {
      return pricesFilter.indexOf(p) === rank - 1;
    });
  }

  let priceMinus = pricesFilter[0][0];
  if (article.priceC && article.priceC < priceMinus) {
    priceMinus = article.priceC;
  }
  if (article.priceB && article.priceB < priceMinus) {
    priceMinus = article.priceB;
  }

  return (
    <div className="article-bloc">
      <Row>
        <Col md="1" style={{ paddingLeft: "10px" }}>
          <p>
            <b>
              {departmentCode} {lot.code} {article.code} {rank}
            </b>
          </p>
        </Col>
        <Col md="3">
          <p>
            <b>{article.name}</b>
          </p>
        </Col>
        <Col md="2">
          <p>
            à partir de{" "}
            <b>
              {centimesToCurrency(priceMinus)} / {article.unit}
            </b>
          </p>
        </Col>
        <Col md="1" style={{ paddingLeft: "10px" }}>
          <Input
            type="number"
            min="0"
            max="1000000"
            value={orderArticle.quantity}
            disabled={true}
          />
        </Col>
        <Col md="2">
          <div>
            <span>
              Prix unitaire HT : <br />
              <b>{centimesToCurrency(orderArticle.price)}</b>
            </span>
          </div>
        </Col>
        <Col md="1">
          <div>
            <span>
              Total HT : <br />
              <b>
                {centimesToCurrency(orderArticle.price * orderArticle.quantity)}
              </b>
            </span>
          </div>
        </Col>
        <Col md="2" className="vertical-align-center">
          <div className="text-right d-flex flex-column" style={{ marginRight: "15px" }}>
            {session?.user?.id &&
              customerId &&
              session.user.id === customerId &&
              !isProposal && (
                <Button
                  onClick={(e) => removeArticle(orderLot, orderArticle)}
                  className="btn-grey mb-2"
                >
                  Retirer du panier
                </Button>
              )}

            {showDetail && !isProposal && (
              <Button
                className="btn-white"
                onClick={() => updateToggle(!detailToggle, article.id)}
              >
                Détails
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Collapse isOpen={detailToggle}>
            <div style={{ padding: "20px 10px" }}>
              <h4 style={{ fontSize: "20px", marginBottom: "20px" }}>
                <b style={{ color: "#DB7124" }}>{lot.code}</b> : {lot.name}
              </h4>
              <p style={{ marginBottom: "30px" }}>{article.description}</p>
              {article.prices ? (
                <div>
                  <div>
                    <p>Prix unitaire des entreprises:</p>
                  </div>
                  <ArticleVariations
                    quantityB={article.quantityB}
                    quantityC={article.quantityC}
                    prices={article.prices}
                    unit={article.unit}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    <p>Aucun prestataire</p>
                  </div>
                </div>
              )}
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

Article.propTypes = {};

Article.defaultProps = {};

export default Article;
