import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {Row, Col, FormGroup, Input} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/fr";
import Account from "./../Account";
import BlocInfos from "views/universal/BlocInfos/BlocInfos";
import IconPanier from "assets/img/icon-panier.svg";
import IconTrash from "assets/img/icon_trash.svg";
import {centimesToCurrency} from "utils/Utils";
import {sessionOperations} from "state/ducks/session";
import RetrieveCart from "../RetrieveCart/RetrieveCart";

const Carts = (props) => {
    const dispatch = useDispatch();
    // const [] = useState(1);
    const [cartFilter, setCartFilter] = useState('all'); // 'all', 'formalized', 'free'

    const session = useSelector((state) => state.session, shallowEqual);

    // Si c'est en proposal ou pas le listing

    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (session.isLoading === false && session.init === true && session.confirmedCarts.init === false && initialized === false) {
            dispatch(sessionOperations.getConfirmedCarts(session.user.id, 1, "formalized"));
            dispatch(sessionOperations.getConfirmedCarts(session.user.id, 1, "free"));
            setInitialized(true);
        }
    }, [dispatch, session, initialized]);
    // const responseToProposal = (cart, response) => {
    //     let cartType = cart.orderFreeSuppliers ? "free" : "formalized";
    //     return dispatch(sessionOperations.responseToProposalCart(cart.id, cartType, response)).then((response) => {
    //         if (cartType === "free") {
    //             history.push(`/compte/panier/libre/${cart.id}`);
    //         } else {
    //             history.push(`/compte/panier/${cart.id}`);
    //         }
    //     });
    // };
    const loadMore = () => {
        let currentPageNumber = 0;
        if (
            session.confirmedCarts &&
            session.confirmedCarts.pages &&
            session.confirmedCarts.pages.length > 0
        ) {
            currentPageNumber = Math.max(...session.confirmedCarts.pages);
        }
        dispatch(
            sessionOperations.getConfirmedCarts(session.user.id, currentPageNumber + 1, "formalized")
        );
        dispatch(
            sessionOperations.getConfirmedCarts(session.user.id, currentPageNumber + 1, "free")
        );
    };
    const removeCart = (cartId, cartType) => {
        if (window.confirm("Voulez-vous vraiment supprimer ce panier ?")) {
            dispatch(sessionOperations.removeCart(cartId, cartType))
        }
    }

    const renderBlocDetails = (value) => {
        const blocPanier = document.querySelector(`#c${value}`);
        blocPanier.style.display = blocPanier.style.display === "block" ? "none" : "block";
    };

    function StatutPanier({status}) {
        let contenu;

        switch (status) {
            case 1:
                contenu = <div className="statut-cart">Proposé</div>;
                break;
            case 2:
                contenu = <div className="statut-cart">Confirmé</div>;
                break;
            case 3:
                contenu = <div className="statut-cart">Terminé</div>;
                break;
            case 4:
                contenu = <div className="statut-cart">Annulé</div>;
                break;
            case 5:
                contenu = <div className="statut-cart">Livré</div>;
                break;
            case 10:
                contenu = <div className="statut-cart">Proposé</div>;
                break;
            case 11:
                contenu = <div className="statut-cart">Refusé</div>;
                break;
            default:
                contenu = <div className="statut-cart">Statut Inconnu {status}</div>;
        }

        return contenu;
    }

    function StatutSentPanier({status}) {
        let contenu;

        switch (status) {
            case 0:
                contenu = <div className="statut-cart">Non reçu</div>;
                break;
            case 1:
                contenu = <div className="statut-cart">en attente</div>;
                break;
            case 2:
                contenu = <div className="statut-cart">accepté</div>;
                break;
            case 3:
                contenu = <div className="statut-cart">refusé</div>;
                break;
            case 4:
                contenu = <div className="statut-cart">sans réponse</div>;
                break;
            case 5:
                contenu = <div className="statut-cart">annulé</div>;
                break;
            default:
                contenu = <div className="statut-cart">Statut Inconnu {status}</div>;
        }

        return contenu;
    }

    function StatutSentPanierText({status}) {
        let contenu;

        switch (status) {
            case 'non reçu':
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>Non reçu</div>;
                break;
            case 'en attente':
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>en attente</div>;
                break;
            case 'accepté':
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>accepté</div>;
                break;
            case 'refusé':
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>refusé</div>;
                break;
            case 'sans réponse':
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>sans réponse</div>;
                break;
            case 'annulé':
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>annulé</div>;
                break;
            default:
                contenu = <div className="statut-cart" style={{marginBottom: '10px'}}>Statut Inconnu</div>;
        }

        return contenu;
    }

    const renderNotSentPanier = (panier, cartType) => {
        return (
            <div key={panier.id} className="bloc-panier">
                <Row style={{marginBottom: "10px"}}>
                    <Col md={2} style={{display: 'flex'}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {cartType === 'freeProposal' || cartType === 'freePending' ?
                                <div className="statut-free">libre</div> :
                                <div className="statut-formalized">formalisé</div>}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span>{moment(panier.date).locale('fr').format('L')}</span>

                        </div>
                    </Col>
                    <Col md={3} style={{textAlign: "right"}}>
                        <span style={{color: "#2C3948"}}>{centimesToCurrency(panier.price)}
                        </span>
                    </Col>
                    <Col md={3}>
                        <div style={{
                            display: "flex", alignItems: "flex-end", height: "100%", justifyContent: "flex-end"
                        }}>
                            {cartType === 'freeProposal' || cartType === 'freePending' ?
                                // panier.customer == null ? <></> :
                                    <Link to={{pathname: `/compte/panier/libre/${panier.id}`}}>
                                        <button className="btn-green float-right"> Voir le panier</button>
                                    </Link> : <Link to={{pathname: `/compte/panier/${panier.id}`}}>
                                    <button className="btn-green float-right"> Voir le panier</button>
                                </Link>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} style={{display: "flex"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {/*<StatutPanier status={panier.status}/>{panier.status}*/}

                            {cartType === 'freeProposal' || cartType === 'formalizedProposal' ?
                                <div className="statut-cart">Proposé</div> :
                                cartType === 'formalizedPending' || cartType === 'freePending' ?
                                    <div className="statut-cart">En cours</div> :
                                    <StatutPanier status={panier.status}/>}
                        </div>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{panier.name}</b>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="d-flex align-items-center">
                        {cartType === "freeProposal" || cartType === "formalizedProposal" ? panier.supplier.name :
                            <p></p>
                        }
                    </Col>
                    <Col md={3}>

                        <div style={{
                            display: "flex", alignItems: "flex-end", height: "100%", justifyContent: "flex-end"
                        }}>
                            <button className="btn-green float-right"
                                    onClick={() => removeCart(panier.id, cartType)}>
                                <img height="auto" style={{width: "15px"}} src={IconTrash} alt="Icon Trash"/>
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>);
    };

    const detailsCard = (panier) => {
        return (
            <div id={"c" + panier.id} className="bloc-panier-table" style={{marginTop: "20px"}}>
                <div className="bloc-panier-header">
                    <div>N°Bon De Commande</div>
                    <div>Statut</div>
                    <div>Département</div>
                    <div className="bloc-panier-header-lot">Lot</div>
                    <div>Fournisseur</div>
                    <div>Total HT</div>
                    <div>Lien</div>
                </div>
                <div className="bloc-panier-content">
                    {panier.orderLots.map((orderLot, indexF) => {
                        return (<div key={indexF}>
                            <div className="bloc-panier-content-lot">
                                <div className="bloc-panier-content-lot-header">
                                    <div>
                                        {orderLot.orderLotSuppliers.map((orderLotSupplier) => {
                                            return (<p>
                                                {orderLotSupplier.status > 0 && orderLotSupplier.number}
                                            </p>);
                                        })}
                                    </div>

                                    <div>
                                        {orderLot.orderLotSuppliers.map((orderLotSupplier) => {
                                            return (<p>
                                                {orderLotSupplier.status > 0 && orderLotSupplier.statusTextFR}
                                            </p>);
                                        })}
                                    </div>

                                    <div>{panier.postal}</div>
                                    <div className="bloc-panier-content-lot-header-lot">
                                        {orderLot.lotCode} – {orderLot.lotName}
                                    </div>

                                    <div>
                                        {orderLot.orderLotSuppliers.map((orderLotSupplier) => {
                                            return (<p>
                                                {orderLotSupplier.status > 0 && orderLotSupplier.name}
                                            </p>);
                                        })}
                                    </div>

                                    <div>
                                        {orderLot.orderLotSuppliers.map((orderLotSupplier) => {
                                            return (<p>
                                                {orderLotSupplier.status > 0 && centimesToCurrency(orderLotSupplier.price)}
                                            </p>);
                                        })}
                                    </div>

                                    <div>
                                        <a
                                            href={orderLot.publicUrl && orderLot.publicUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Résumé & bons de commande
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
            </div>);
    };

    const renderSentPanier = (panier, cartType) => {
        return (<div key={panier.id} className="bloc-panier">
                <Row style={{marginBottom: "10px"}}>
                    <Col md={2} style={{display: 'flex'}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {cartType === 'free' ? <div className="statut-free">libre</div> :
                                <div className="statut-formalized">formalisé</div>}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span>{moment(panier.date).locale('fr').format('L')}</span>

                        </div>
                    </Col>
                    <Col md={3} style={{textAlign: "right"}}>
                        <span style={{color: "#2C3948"}}>{centimesToCurrency(panier.price)}
                        </span>
                    </Col>
                    <Col md={3}>

                    </Col>
                </Row>
                {/*{panier.orderFreeSuppliers.map((orderFreeSupplier, indexF) => {*/}
                {/*    return (*/}

                <Row>
                    {panier.orderLots[0].orderLotSuppliers.length > 1 ?
                        <Col md={2} style={{display: "flex"}}>
                            <div style={{alignItems: "center"}}>
                                {panier.orderLots[0].orderLotSuppliers.map((orderLotSupplier, indexF) => {
                                    return (
                                        <>
                                            {orderLotSupplier.status > 0 ? <StatutSentPanierText key={indexF}
                                                                                                 status={orderLotSupplier.statusTextFR}/> : <></>}

                                        </>
                                    );

                                })}
                            </div>
                        </Col>
                        :
                        <Col md={2} style={{display: "flex"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <StatutSentPanierText status={panier.orderLots[0].orderLotSuppliers[0].statusTextFR}/>
                            </div>
                        </Col>
                    }

                    <Col md={4} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{panier.name}</b>
                            </li>
                        </ul>
                    </Col>
                    {panier.orderLots[0].orderLotSuppliers.length > 1 ?
                        <Col md={3} className="d-flex align-items-center">
                            <ul className="list-infos-panier">
                                <li>
                                    {panier.orderLots[0].orderLotSuppliers.map((orderLotSupplier, indexF) => {
                                        return (

                                            <b
                                                style={{color: "#2C3948"}}>{orderLotSupplier.status > 0 && orderLotSupplier.status !== 3 && orderLotSupplier.name}</b>
                                        );
                                    })}

                                </li>
                            </ul>
                        </Col> :
                        <Col md={3} className="d-flex align-items-center">
                            <ul className="list-infos-panier">
                                <li><b
                                    style={{color: "#2C3948"}}>{panier.orderLots[0].orderLotSuppliers[0].name}</b>
                                </li>
                            </ul>
                        </Col>
                    }

                    <Col md={3}>

                        <div style={{
                            display: "flex", alignItems: "flex-end", height: "100%", justifyContent: "flex-end"
                        }}>
                            <button className="btn-green float-right"
                                    onClick={() => renderBlocDetails(panier.id)}> Voir plus
                            </button>
                        </div>
                    </Col>
                </Row>
                {/*{console.log(panier.orderLots[0].orderLotSuppliers.length)}*/}
                {/*{panier.orderLots[0].orderLotSuppliers.length > 1 ?*/}
                {/*    <Row>*/}
                {/*        <Col md={2} style={{display: "flex"}}>*/}
                {/*            <div style={{display: "flex", alignItems: "center"}}>*/}
                {/*                <StatutSentPanierText status={panier.orderLots[0].orderLotSuppliers[0].statusTextFR}/>*/}
                {/*            </div>*/}
                {/*        </Col>*/}
                {/*    </Row> : <></>}*/}

                <Row>
                    <Col md={12}>
                        {panier.orderLots !== null ? detailsCard(panier) : null}
                    </Col>
                </Row>
            </div>
        );
    };
    const renderSentFreePanier = (panier) => {
        return (
            <div key={panier.id} className="bloc-panier">
                <Row style={{marginBottom: "10px"}}>
                    <Col md={2} className="flex">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className="statut-free">libre</div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span>{moment(panier.date).locale('fr').format('L')}</span>
                        </div>
                    </Col>
                    <Col md={3} style={{textAlign: "right"}}>
                        <span style={{color: "#2C3948"}}>{centimesToCurrency(panier.price)}
                        </span>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} style={{textAlign: "right"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <StatutSentPanier status={panier.orderFreeSuppliers[0].status}/>
                        </div>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{panier.name}</b>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="d-flex align-items-center">
                        <ul className="list-infos-panier">
                            <li><b
                                style={{color: "#2C3948"}}>{panier.orderFreeSuppliers[0].supplierName}</b>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3}>

                        <div style={{
                            display: "flex", alignItems: "flex-end", height: "100%", justifyContent: "flex-end"
                        }}>
                            <button className="btn-green float-right"
                                    onClick={() => renderBlocDetails(panier.id)}> Voir plus
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {panier.orderFreeArticles !== null ? detailsFreeCard(panier) : null}
                    </Col>
                </Row>
            </div>
        );
    };

    const detailsFreeCard = (panier) => {
        return (
            <div id={"c" + panier.id} className="bloc-panier-table" style={{marginTop: "20px"}}>
                <div className="bloc-panier-header">
                    <div>N°Bon De Commande</div>
                    <div>Statut</div>
                    <div>Département</div>
                    <div>Fournisseur</div>
                    <div>Total HT</div>
                    <div>Lien</div>
                </div>
                <div className="bloc-panier-content">
                    {panier.orderFreeSuppliers.map((orderFreeSupplier, indexF) => {
                        return (
                            <div key={indexF}>
                                <div className="bloc-panier-content-lot">
                                    <div className="bloc-panier-content-lot-header">
                                        <div>
                                            <p>{orderFreeSupplier.id}</p>
                                        </div>

                                        <div>
                                            <p>
                                                {orderFreeSupplier.status > 0 &&
                                                    orderFreeSupplier.statusTextFR}
                                            </p>
                                        </div>

                                        <div>{panier.postal}</div>

                                        <div>
                                            <p>
                                                {orderFreeSupplier.status > 0 &&
                                                    orderFreeSupplier.supplierName}
                                            </p>
                                        </div>

                                        <div>
                                            <p>
                                                {orderFreeSupplier.status > 0 &&
                                                    centimesToCurrency(orderFreeSupplier.price)}
                                            </p>
                                        </div>

                                        <div>
                                            <a
                                                href={
                                                    orderFreeSupplier.publicUrl &&
                                                    orderFreeSupplier.publicUrl
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Résumé & bons de commande
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const renderPaniers = () => {
        if (session.pendingCarts.length < 1 && session.confirmedCarts.length < 1) {
            return <BlocInfos title="Vous n'avez aucun panier" icon={IconPanier}/>;
        } else {
            return (
                <>
                <RetrieveCart/>
                    <Row>
                        <Col lg="4">
                            <FormGroup className="mb-3">
                                <Input
                                    type="select"
                                    name="selectType"
                                    id="selectType"
                                    value={cartFilter}
                                    onChange={(e) => setCartFilter(e.target.value)}>

                                    <option value="all">Tous les paniers</option>
                                    <option value="formalized">Formalisé</option>
                                    <option value="free">Libre</option>


                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <span>{cartFilter === 'formalized' && session.proposalCarts && session.proposalCarts.length > 0 && session.proposalCarts.map((cart) => renderNotSentPanier(cart, "formalizedProposal"))}</span>
                    <span>{cartFilter === 'free' && session.proposalFreeCarts && session.proposalFreeCarts.length > 0 && session.proposalFreeCarts.map((cart) => renderNotSentPanier(cart, "freeProposal"))}</span>
                    <span>{cartFilter === 'formalized' && session.pendingCarts && session.pendingCarts.length > 0 && session.pendingCarts.map((cart) => renderNotSentPanier(cart, 'formalizedPending'))}</span>
                    <span>{cartFilter === 'free' && session.pendingFreeCarts && session.pendingFreeCarts.length > 0 && session.pendingFreeCarts.map((cart) => renderNotSentPanier(cart, "freePending"))}</span>
                    <span>{cartFilter === 'formalized' && session.confirmedCarts && session.confirmedCarts.data && session.confirmedCarts.data.length > 0 && session.confirmedCarts.data.map((cart) => renderSentPanier(cart))}</span>
                    <span>{cartFilter === 'free' && session.confirmedFreeCarts && session.confirmedFreeCarts.data && session.confirmedFreeCarts.data.length > 0 && session.confirmedFreeCarts.data.map((cart) => renderSentFreePanier(cart))}</span>

                    <span>{cartFilter === 'all' && session.proposalCarts && session.proposalCarts.length > 0 && session.proposalCarts.map((cart) => renderNotSentPanier(cart, "formalizedProposal"))}</span>
                    <span>{cartFilter === 'all' && session.proposalFreeCarts && session.proposalFreeCarts.length > 0 && session.proposalFreeCarts.map((cart) => renderNotSentPanier(cart, "freeProposal"))}</span>
                    <span>{cartFilter === 'all' && session.pendingCarts && session.pendingCarts.length > 0 && session.pendingCarts.map((cart) => renderNotSentPanier(cart, 'formalizedPending'))}</span>
                    <span>{cartFilter === 'all' && session.pendingFreeCarts && session.pendingFreeCarts.length > 0 && session.pendingFreeCarts.map((cart) => renderNotSentPanier(cart, "freePending"))}</span>
                    <span>{cartFilter === 'all' && session.confirmedCarts && session.confirmedCarts.data && session.confirmedCarts.data.length > 0 && session.confirmedCarts.data.map((cart) => renderSentPanier(cart))}</span>
                    <span>{cartFilter === 'all' && session.confirmedFreeCarts && session.confirmedFreeCarts.data && session.confirmedFreeCarts.data.length > 0 && session.confirmedFreeCarts.data.map((cart) => renderSentFreePanier(cart))}</span>
                </>)
                ;
        }
    };

    return <Account>{renderPaniers()}
        {session.confirmedFreeCarts &&
            session.confirmedFreeCarts.data &&
            session.confirmedFreeCarts.data.length > 0 &&
            session.confirmedCarts &&
            session.confirmedCarts.data &&
            session.confirmedCarts.data.length > 0 &&
            (
                <button onClick={() => loadMore()} className="btn-green float-right">
                    Charger plus
                </button>
            )}
    </Account>;
};

Carts.propTypes = {};

Carts.defaultProps = {};

export default Carts;
