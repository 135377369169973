import React from "react";
import {Container, Row, Col} from "reactstrap";
import Customer from "./Customer";
import MiniLoader from "views/universal/Loader/MiniLoader/MiniLoader";

const CustomersList = (props) => {
    const {customers} = props;

    return (
        <section className="p-section">
            <Container>
                {/*<Row>*/}
                    <Row className="align-items-stretch" style={{marginLeft: "3,3px", marginRight: "3,3px"}}>

                        {customers.isLoading ? (
                            <MiniLoader/>
                        ) : (
                            customers.data.map((customer, index) => {
                                return (
                                    <>
                                        {customer?.roles &&
                                            customer.roles.includes("ROLE_SUPER_CUSTOMER") && (
                                                <Col md={4} key={index} style={{paddingRight : "0px"}}>
                                                    <Customer customer={customer} index={index} key={index}/>
                                                </Col>
                                            )}
                                    </>
                                );
                            })
                        )}
                    </Row>
                {/*</Row>*/}
            </Container>
        </section>
    );
};

export default CustomersList;
